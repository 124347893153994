import React, { useState } from "react"
import { Dialog, DialogContent, Typography, Button, TextField } from "@mui/material"
import { toast } from "react-toastify"

/**
 * Propsit:
 *  - open: boolean, avataanko dialog
 *  - onClose: sulkeeko dialogin
 *  - sendPasswordResetEmail: dataService-funktio, joka lähettää resetointilinkin
 */
interface ForgotPasswordDialogProps {
	open: boolean
	onClose: () => void
	sendPasswordResetEmail: (requestBody: { loginId: string }) => Promise<any>
}

const ForgotPasswordDialog = ({ open, onClose, sendPasswordResetEmail }: ForgotPasswordDialogProps) => {
	const [email, setEmail] = useState("")

	/**
	 * Sulkee dialogin
	 */
	const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		onClose()
	}

	/**
	 * Lähettää resetointilinkin syötettyyn sähköpostiin
	 */
	const handleSendResetEmail = async () => {
		if (!email) {
			toast.error("Sähköpostiosoite puuttuu.")
			return
		}
		try {
			await sendPasswordResetEmail({ loginId: email })
			toast.success("Linkki lähetetty sähköpostiin.")
			setEmail("")
			onClose()
		} catch (error) {
			// Varsinainen virheilmoitus käsitellään dataServicessä
		}
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent className="forgot-password-dialog-content">
				<button className="close-dialog-button" onClick={handleClose}>
					X
				</button>

				<Typography variant="h6" className="forgot-password-title">
					Unohditko salasanasi?
				</Typography>

				<TextField
					autoFocus
					margin="dense"
					label="Sähköpostiosoite"
					type="email"
					fullWidth
					variant="outlined"
					value={email}
					onChange={(e: any) => setEmail(e.target.value)}
					className="forgot-password-textfield"
				/>

				<Button variant="contained" className="forgot-password-button" onClick={handleSendResetEmail}>
					Lähetä resetointilinkki
				</Button>
			</DialogContent>
		</Dialog>
	)
}

export default ForgotPasswordDialog
