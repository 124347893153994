import { Box } from "@mui/material"
import { TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { MenuItem } from "@mui/material"
import { Grid } from "@mui/material"
import { getAllCountries } from "../../../network/dataServices/generalDataService"
import { Select } from "@mui/material"
import { FormControl } from "@mui/material"
import { InputLabel } from "@mui/material"

const ContactPersonUserInfo = ({ contactPerson, setContactPerson }: any) => {
	const [allCountries, setAllCountries] = useState<Country[]>([])

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
	}, [])

	return (
		<div>
			<Box sx={{ textAlign: "center" }}>
				<Grid container xs={12}>
					<Grid item xs={12} sm={6}>
						<TextField
							label="Nimi"
							multiline
							variant="standard"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, name: e.target.value })}
							value={contactPerson.name}
						/>
						<br />
						<TextField
							label="Lisätieto"
							multiline
							variant="standard"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, additionalInformation: e.target.value })}
							value={contactPerson.additionalInformation}
						/>
						<br />
						<TextField
							label="Sähköposti"
							multiline
							variant="standard"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, email: e.target.value })}
							value={contactPerson.email}
						/>{" "}
						<br />
						<TextField
							label="Puhelinnumero"
							multiline
							variant="standard"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, phoneNumber: e.target.value })}
							value={contactPerson.phoneNumber}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							label="Postiosoite"
							multiline
							variant="standard"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, billingAddress: e.target.value })}
							value={contactPerson.billingAddress}
						/>
						<br />
						<TextField
							label="Postinumero"
							multiline
							variant="standard"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, postalCode: e.target.value })}
							value={contactPerson.postalCode}
						/>
						<br />
						<TextField
							label="Postitoimipaikka"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, city: e.target.value })}
							value={contactPerson.city}
						/>

						<br />
						{/* <TextField
							label="Maa"
							onChange={(e: any) => setContactPerson({ ...contactPerson, countryCode: e.target.value })}
							value={contactPerson.countryCode}
							variant="standard"
							select
							fullWidth
							sx={{ maxWidth: "165px", zIndex: 1305, overFlow: "hidden" }}
							inputProps={{ sx: { zIndex: 1305 } }}
							SelectDisplayProps={{ sx: { zIndex: 1305 } }}
							MenuProps={{ sx: { zIndex: 1305 } }}
						> */}
						<TextField
							select
							variant="standard"
							SelectProps={{
								MenuProps: {
									style: {
										zIndex: 1305
									}
								}
							}}
							fullWidth
							label="Maa"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, countryCode: e.target.value })}
							value={contactPerson.countryCode}
						>
							{allCountries
								.sort((a, b) => a.country.localeCompare(b.country, "fi"))
								.map((country: Country) => {
									return (
										<MenuItem sx={{ overFlow: "hidden", zIndex: 1305 }} key={country.countryCode.toString()} value={country.countryCode}>
											{country.country} - {country.countryCode}
										</MenuItem>
									)
								})}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={12}></Grid>
				</Grid>
			</Box>
		</div>
	)
}

export default ContactPersonUserInfo
