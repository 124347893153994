import React, { useEffect, useRef, useState } from "react"
import { ReactComponent as InfoIcon } from "../../resources/icons/info.svg"
import CloseIcon from "@mui/icons-material/Close"

import { Tooltip, Typography, Paper, Box, IconButton } from "@mui/material"
import DialogCloseButton from "./DialogCloseButton"
import { Collapse } from "@mui/material"
import { SxProps } from "@mui/system"

type LinkButtonProps = {
	infoIconSx?: SxProps
	text: React.ReactElement
	pointerDirection: string
	subclass: string
}
const InfoButtonPaper = ({ text, pointerDirection, subclass, infoIconSx }: LinkButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null) // Viite painikkeelle
	const [open, setOpen] = useState(false) // Popupin tila
	const [position, setPosition] = useState({ top: 0, left: 0 }) // Popupin sijainti

	const updatePosition = () => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect()
			let top = 0
			let left = 0

			switch (pointerDirection) {
				case "top":
					top = rect.bottom + window.scrollY + 30
					left = rect.left + rect.width / 2 + window.scrollX
					break

				case "right":
					top = rect.top + rect.height / 2 + window.scrollY - 60
					left = rect.left + window.scrollX - 240
					break
				case "left":
					top = rect.top - rect.height / 2 + window.scrollY - 70
					left = rect.right + window.scrollX + 240
					break
				default:
					top = rect.bottom + window.scrollY + 30
					left = rect.left + rect.width / 2 + window.scrollX
			}

			setPosition({ top, left })
		}
	}

	useEffect(() => {
		console.log(text)
		if (open) {
			// Päivitä popupin sijainti, kun ikkunan koko muuttuu
			window.addEventListener("resize", updatePosition)

			// Päivitä sijainti, kun popup avataan
			updatePosition()
		}

		// Poista tapahtumakuuntelija, kun popup suljetaan
		return () => {
			window.removeEventListener("resize", updatePosition)
		}
	}, [open])
	return (
		<>
			{/* IconButton */}
			<Tooltip title={!open ? "Näytä lisää klikkaamalla" : null}>
				<IconButton
					ref={buttonRef}
					variant="contained"
					onClick={() => setOpen(!open)}
					sx={infoIconSx}
					// {{
					// 	fontSize: 15,
					// 	color: "#34aadc",
					// 	top: styling?.top,
					// 	left: styling?.left,
					// 	bottom: styling?.bottom,
					// 	right: styling?.right
					// }}
				>
					<InfoIcon />
				</IconButton>
			</Tooltip>

			{/* Popup, jos open=true */}
			{open && (
				<Box
					sx={{
						position: "absolute", // Popup kelluu absoluuttisena komponenttina
						top: position.top, // Popupin Y-koordinaatti
						left: position.left, // Popupin X-koordinaatti
						transform: "translateX(-50%)", // Keskitetään popup X-akselilla
						zIndex: 1000 // Popup näkyy muiden komponenttien päällä
					}}
				>
					{/* Nuoli */}
					<div
						className={`onboarding-helper onboarding-helper-arrow ${pointerDirection} ${subclass}`}
						style={{
							position: "absolute",
							top: "-10px", // Nuoli popupin yläpuolella
							left: "50%",
							transform: "translateX(-50%)",
							padding: "20px", // Lisää tilaa tekstin ympärille

							backgroundColor: "#fff", // Taustaväri
							borderRadius: "5px", // Pyöristetyt kulmat
							boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Varjo popupille
							display: "inline-block", // Mukauttaa leveyden tekstin mukaan
							textAlign: "center", // Keskittää tekstin vaakasuunnassa
							zIndex: 1000,
							width: "400px"
						}}
					>
						{/* Close-painike */}
						<IconButton
							sx={{
								position: "absolute",
								right: "5px", // Sijoita painike oikeaan ylänurkkaan
								top: "5px" // Sijoita painike yläreunaan
							}}
							onClick={() => setOpen(false)} // Sulkee popupin
						>
							<CloseIcon />
						</IconButton>
						<Box>{text}</Box>
					</div>

					{/* Popup-ikkuna */}
				</Box>
			)}
		</>
	)
}
export default InfoButtonPaper
