// Tuodaan kaikki tarvittavat kuvat etukäteen
import asunto from "../../resources/roadUsageUnitIcons/01_tievahti-ikoni-asunto.png"
import lomaAsunto from "../../resources/roadUsageUnitIcons/02_tievahti-ikoni-loma-asunto.png"
import mokki from "../../resources/roadUsageUnitIcons/03_tievahti-ikoni-mokki.png"
import tontti from "../../resources/roadUsageUnitIcons/04_tievahti-ikoni-tontti.png"
import metsa from "../../resources/roadUsageUnitIcons/05_tievahti-ikoni-metsa.png"
import peltoVilja from "../../resources/roadUsageUnitIcons/06_tievahti-ikoni-pelto-vilja.png"
import peltoKarja from "../../resources/roadUsageUnitIcons/07_tievahti-ikoni-pelto-karja.png"
import muuTienkayttaja from "../../resources/roadUsageUnitIcons/08_tievahti-ikoni-muu-tienkayttaja.png"

// Määritellään tietorakenne kuva-aineistolle
const iconData: { [key: string]: { description: string; path: string } } = {
	"01_tievahti-ikoni-asunto.png": {
		description: "Asunto",
		path: asunto
	},
	"02_tievahti-ikoni-loma-asunto.png": {
		description: "Ympärivuotinen mökki",
		path: lomaAsunto
	},
	"03_tievahti-ikoni-mokki.png": {
		description: "Kesä- / Lomamökki",
		path: mokki
	},
	"04_tievahti-ikoni-tontti.png": {
		description: "Rakentamaton tontti",
		path: tontti
	},
	"05_tievahti-ikoni-metsa.png": {
		description: "Metsä",
		path: metsa
	},
	"06_tievahti-ikoni-pelto-vilja.png": {
		description: "Pelto",
		path: peltoVilja
	},
	"07_tievahti-ikoni-pelto-karja.png": {
		description: "Karja",
		path: peltoKarja
	},
	"08_tievahti-ikoni-muu-tienkayttaja.png": {
		description: "Erityis- / Muu liikenne",
		path: muuTienkayttaja
	}
}

// Funktio kuvauksen valitsemiseksi
export const selectIconDescription = (fileName: string): string => {
	return iconData[fileName]?.description || "Tuntematon kuvake"
}

interface ImgModule {
	default: string
}

// Funktio oikean kuvan lataamiseksi
export const getRightImage = async (fileName: string): Promise<string | null> => {
	const data = iconData[fileName]
	if (data) {
		try {
			const x = data.path
			return x
		} catch (error) {
			console.error("Virhe kuvan latauksessa:", error)
			return null
		}
	}
	return null
}
