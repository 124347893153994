import { Box } from "@mui/material"
import { TextField } from "@mui/material"
import { MenuItem } from "@mui/material"
import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getAllCountries } from "../../../network/dataServices/generalDataService"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import { Select } from "@mui/material"

const ContactOrganisationUserInfo = ({ contactPerson, setContactPerson }: any) => {
	// const [userProfile, setUserProfile] = useState({ name: "", email: "" })
	const [allCountries, setAllCountries] = useState<Country[]>([])

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
		const user = userProfileStorage({ type: "getUserProfile" })
		console.log(user)
		// setUserProfile(user)
	}, [])

	return (
		<div>
			<Box sx={{ textAlign: "center" }}>
				<Grid container xs={12}>
					<Grid item xs={12} sm={6}>
						<TextField
							label="Yrityksen nimi"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, name: e.target.value })}
							value={contactPerson.name}
						/>
						<br />
						<TextField
							label="Lisätietoja"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, additionalInformation: e.target.value })}
							value={contactPerson.additionalInformation}
						/>
						<br />
						<TextField
							label="Sähköposti"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, email: e.target.value })}
							value={contactPerson.email}
						/>{" "}
						<br />
						<TextField
							label="Puhelinnumero"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, phoneNumber: e.target.value })}
							value={contactPerson.phoneNumber}
						/>{" "}
						<br />
						<TextField
							label="Y-tunnus"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, businessId: e.target.value })}
							value={contactPerson.businessId}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							label="Postiosoite"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, billingAddress: e.target.value })}
							value={contactPerson.billingAddress}
						/>
						<br />
						<TextField
							label="Postinumero"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, postalCode: e.target.value })}
							value={contactPerson.postalCode}
						/>
						<br />
						<TextField
							label="Postitoimipaikka"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, city: e.target.value })}
							value={contactPerson.city}
						/>
						<br />

						<TextField
							label="Verkkolaskutusosoite"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, eInvoiceAddress: e.target.value })}
							value={contactPerson.eInvoiceAddress}
						/>
						<br />
						<TextField
							label="Operaattoritunnus"
							multiline
							variant="standard"
							onChange={(e: any) => setContactPerson({ ...contactPerson, eInvoiceOperator: e.target.value })}
							value={contactPerson.eInvoiceOperator}
						/>

						<br />
					</Grid>
					<Grid item xs={12} sm={12} sx={{ zIndex: 1305 }}>
						<TextField
							select
							variant="standard"
							SelectProps={{
								MenuProps: {
									style: {
										zIndex: 1305
									}
								}
							}}
							sx={{ width: "50%" }}
							label="Maa"
							onChange={(e: { target: { value: string } }) => setContactPerson({ ...contactPerson, countryCode: e.target.value })}
							value={contactPerson.countryCode}
						>
							{allCountries
								.sort((a, b) => a.country.localeCompare(b.country, "fi"))
								.map((country: Country) => {
									return (
										<MenuItem sx={{ overFlow: "hidden", zIndex: 1305 }} key={country.countryCode.toString()} value={country.countryCode}>
											{country.country} - {country.countryCode}
										</MenuItem>
									)
								})}
						</TextField>
					</Grid>
				</Grid>
			</Box>
		</div>
	)
}

export default ContactOrganisationUserInfo
