import React, { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import maplibregl from "maplibre-gl"

import { Paper, Typography, Fade } from "@mui/material"
import { Grid } from "@mui/material"
import { RoadUsageUnits } from "./MapComponent"
import { getRightImage } from "../reusables/CommonFunctions"

interface MarkerProps {
	maps: maplibregl.Map
	coordinates: [number, number]
	establishments: any
	iconState: { size: number; opacity: number; gapBetweenIcons: number }
	selectedRoadUsageUnits: RoadUsageUnits
}

interface IkoniProps {
	key: string
	ikoni: string
	establishment: any
	iconState: { size: number; opacity: number; gapBetweenIcons: number }
	index: number
	estLength: number
	openedIconId: number | null
	setOpenedIconId: (i: number | null) => void
}

const Markeri: React.FC<MarkerProps> = ({ maps, coordinates, establishments, iconState, selectedRoadUsageUnits }) => {
	const [openedIconId, setOpenedIconId] = useState<number | null>(null) // Tila avattua ikonia varten
	const [filteredEstablishments, setFilteredEstablishments] = useState<any>([])

	const markerRef = useRef<HTMLDivElement>(document.createElement("div"))
	markerRef.current.style.justifyContent = "center"
	markerRef.current.style.alignItems = "center"
	markerRef.current.style.display = "flex"
	markerRef.current.style.zIndex = openedIconId !== null ? "4" : "2"
	markerRef.current.style.position = "absolute"

	useEffect(() => {
		if (!maps || !coordinates || !markerRef.current) return

		const marker = new maplibregl.Marker({
			element: markerRef.current
		})
			.setLngLat(coordinates)
			.addTo(maps)

		return () => {
			marker.remove()
		}
	}, [maps, coordinates])

	useEffect(() => {
		const selectedNamesSet = new Set(selectedRoadUsageUnits.map((unit: any) => unit.name))

		const filter =
			selectedRoadUsageUnits.length > 0
				? establishments.filter((est: any) => {
						const roadUsageUnitType = est.roadUsageUnitByRoadUsageUnitId?.roadUsageUnitType

						return (
							selectedNamesSet.has(roadUsageUnitType) &&
							est.roadUsageUnitByRoadUsageUnitId?.iconImageName !== "" &&
							est.roadUsageUnitByRoadUsageUnitId?.iconImageName
						)
					})
				: establishments.filter((est: any) => {
						return est.roadUsageUnitByRoadUsageUnitId?.iconImageName !== "" && est.roadUsageUnitByRoadUsageUnitId?.iconImageName
					})

		setFilteredEstablishments(filter)
	}, [selectedRoadUsageUnits])

	return markerRef.current && filteredEstablishments
		? ReactDOM.createPortal(
				<div
					style={{
						pointerEvents: "auto",
						position: "fixed",
						width: iconState.size,
						height: iconState.size,
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						flexDirection: "row",
						// border: "1px solid black", // Ohuet mustat reunat

						top: iconState.size / 10,
						transition: "transform 0.3s ease, width 1s ease, height 1s ease, opacity 1s ease, top 1s ease" // Korjattu
					}}
				>
					{filteredEstablishments.map((est: any, index: number) => (
						<React.Fragment key={est.id || index}>
							{
								<RoadUsageUnitIcon
									est={est}
									index={index}
									iconState={iconState}
									estLength={filteredEstablishments.length}
									openedIconId={openedIconId}
									setOpenedIconId={setOpenedIconId}
								></RoadUsageUnitIcon>
							}
						</React.Fragment>
					))}
				</div>,
				markerRef.current
			)
		: null
}

export default Markeri

const useImage = (fileName: string) => {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [image, setImage] = useState<string | null>(null)

	useEffect(() => {
		const fetchImage = async () => {
			try {
				const response = await getRightImage(fileName)
				if (response) {
					setImage(response)
				} else {
					setImage("")
				}
			} catch (err: any) {
				setError(err)
			} finally {
				setLoading(false)
			}
		}

		fetchImage()
	}, [fileName])

	return {
		loading,
		error,
		image
	}
}

// Uusi komponentti jokaiselle ikonille
const RoadUsageUnitIcon: React.FC<{
	est: any
	index: number
	iconState: { size: number; opacity: number; gapBetweenIcons: number }
	estLength: number
	openedIconId: number | null
	setOpenedIconId: (i: number | null) => void
}> = ({ est, index, iconState, estLength, openedIconId, setOpenedIconId }) => {
	const { image } = useImage(est.roadUsageUnitByRoadUsageUnitId.iconImageName)
	const ikoni = image ? image : ""

	return (
		<Ikoni
			key={est.id}
			ikoni={ikoni}
			establishment={est}
			iconState={iconState}
			index={index}
			estLength={estLength}
			openedIconId={openedIconId}
			setOpenedIconId={setOpenedIconId}
		/>
	)
}

const Ikoni: React.FC<IkoniProps> = ({ key, ikoni, establishment, iconState, index, estLength, openedIconId, setOpenedIconId }) => {
	const [open, setOpen] = useState(false)
	const isOpen = openedIconId === index // Tarkistetaan, onko tämä ikoni auki

	// const handleClick = (event: React.MouseEvent) => {
	// 	event.stopPropagation()
	// 	if (isOpen) {
	// 		setOpenedIconId(null) // Suljetaan tämä ikoni
	// 	} else {
	// 		setOpenedIconId(index) // Asetetaan tämä ikoni avautuneeksi
	// 	}
	// }
	useEffect(() => {
		if (!open) {
			setOpenedIconId(null) // Suljetaan tämä ikoni
		} else {
			setOpenedIconId(index) // Asetetaan tämä ikoni avautuneeksi
		}
	}, [open])

	const divRef = useRef<HTMLDivElement | null>(null)

	return (
		<Grid
			ref={divRef}
			key={key}
			// onClick={handleClick}
			onMouseEnter={() => setOpen(true)}
			onMouseLeave={() => setOpen(false)}
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				pointerEvents: "auto",
				// border: "1px solid red",
				width: `${iconState.size / 1.1}px`,
				height: `${iconState.size / 1.1}px`,

				position: "absolute",
				left: `${iconState.gapBetweenIcons * index}px`,
				transition: "transform 0.3s ease, left 0.3s ease, height 0.3s ease, width 1s ease, opacity 1s ease, margin-left 0.3s ease", // Korjattu

				"&:hover": {
					transform: estLength > 1 ? `translate(0px, ${iconState.size / 1.4 / 2}px)` : `translate(0px, 0px)`,
					marginLeft: estLength > 1 ? `${0 / 8}px` : `${iconState.size / 12}px`,
					height: estLength > 1 ? `${iconState.size / 1.1 + iconState.size / 1.4}px` : `${iconState.size / 1.1 + 10}px`
				},
				borderRadius: "0 0 50% 50%"
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					pointerEvents: "auto",
					border: "3px solid grey", // Ohuet mustat reunat
					borderRadius: "50%", // Valkoinen ympyrä
					backgroundColor: "white", // Valkoinen tausta
					width: `${iconState.size / 1.1}px`,
					height: `${iconState.size / 1.1}px`,
					opacity: iconState.opacity,
					marginTop: "auto", // Siirtää tämän divin gridin alaosaan
					transition: "transform 1s ease, width 1s ease, height 1s ease, opacity 1s ease, top 1s ease, left 1s ease "
				}}
			>
				{ikoni ? (
					<img
						src={ikoni}
						alt="Ikoni"
						style={{
							opacity: iconState.opacity,
							width: `${(iconState.size - 10) / 1.1}px`,
							height: `${(iconState.size - 10) / 1.1}px`,
							transition: "transform 1s ease, width 1s ease, height 1s ease, opacity 1s ease, top 1s ease, left 1s ease"
						}}
					/>
				) : (
					<Typography
						sx={{
							fontSize: `${iconState.size / 9}px`, // Mukauta jakoarvo tarpeen mukaan
							textAlign: "center",
							paddingTop: `${(iconState.size - 10) / 10}px`,
							width: `${(iconState.size - 10) / 1.1}px`,
							height: `${(iconState.size - 10) / 1.1}px`,
							transition: "font-size 1s ease, width 1s ease, height 1s ease" // Lisää transition-ominaisuus
						}}
					>
						Valitse {<br />}kuvake uudelleen asetuksista
					</Typography>
				)}
			</div>
			{open && iconState.opacity == 1 && (
				<Fade in={open} timeout={1000}>
					<Paper
						sx={{
							position: "absolute",
							opacity: iconState.opacity,
							top: "100%",
							left: "50%",
							transform: "translateX(-50%)",
							marginTop: "10px",
							padding: "5px",
							width: "140px",
							textAlign: "center",
							pointerEvents: "auto",
							backgroundColor: "white",
							boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
							transition: "transform 1s ease, width 1s ease, height 1s ease, opacity 1s ease, top 1s ease, left 1s ease"
						}}
					>
						<Typography>{establishment.roadUsageUnitByRoadUsageUnitId.roadUsageUnitType} </Typography>
						<Typography sx={{ fontWeight: 400 }}>Painoluku: {establishment.roadUsageUnitByRoadUsageUnitId.weight} </Typography>
					</Paper>
				</Fade>
			)}
		</Grid>
	)
}
