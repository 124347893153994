import { gql, useMutation } from "@apollo/client"
import { Typography, Stack } from "@mui/material"
import { TableRow } from "@mui/material"
import { IconButton } from "@mui/material"
import { TableCell } from "@mui/material"
import React, { useEffect, useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import { Dialog } from "@mui/material"
import { Button } from "@mui/material"
import { DialogContent } from "@mui/material"
import { TextField } from "@mui/material"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import MonetaryInput from "../../../utils/MonetaryInput"
import NotificationDialog from "../../reusables/NotificationDialog"
import DeleteChip from "../../reusables/DeleteChip"
import { MenuItem } from "@mui/material"

import AgricultureIcon from "@mui/icons-material/Agriculture"
import ForestIcon from "@mui/icons-material/Forest"
import BusinessIcon from "@mui/icons-material/Business"
import HomeIcon from "@mui/icons-material/Home"
import { Tooltip } from "@mui/material"
import { toast } from "react-toastify"
import { UNITIZATION_ROAD_USAGE_UNIT_MUTATIONS } from "./RoadUsageUnitsTable"
import { FormControl } from "@mui/material"
import { InputLabel } from "@mui/material"
import { Select } from "@mui/material"
import { Box } from "@mui/material"
import { selectIconDescription } from "../../reusables/CommonFunctions"

const UPDATE_ROAD_USAGE_UNIT = gql`
	mutation UpdateRoadUsageUnit(
		$id: Int = 10
		$roadUsageUnit: String = ""
		$roadUsageUnitType: String = ""
		$roadUsageUnitPurpose: UsageUnitPurposeType
		$weight: Float = 1.5
		$iconImageName: String = ""
	) {
		updateRoadUsageUnitById(
			input: {
				roadUsageUnitPatch: {
					roadUsageUnit: $roadUsageUnit
					roadUsageUnitType: $roadUsageUnitType
					roadUsageUnitPurpose: $roadUsageUnitPurpose
					weight: $weight
					iconImageName: $iconImageName
				}
				id: $id
			}
		) {
			clientMutationId
		}
	}
`

const DELETE_ROAD_USAGE_UNIT = gql`
	mutation DeleteRoadUsageUnit($id: Int = 10) {
		deleteRoadUsageUnitById(input: { id: $id }) {
			deletedRoadUsageUnitId
		}
	}
`

interface RoadUsageUnitWithEstablishments extends RoadUsageUnit {
	establishmentsByRoadUsageUnitId: {
		totalCount: number
	}
}

interface Icon {
	fileName: string
	image: string // tai jokin muu tarvittava tyyppi
}

const RoadUsageUnitsTableRow = ({
	unit,
	usageUnitsRefetch,
	icons
}: {
	unit: RoadUsageUnitWithEstablishments
	usageUnitsRefetch: () => void
	icons: { fileName: string; image: any }[]
}) => {
	// console.log(unit)
	const [openEdit, setOpenEdit] = useState(false)

	const [usageUnitType, setUsageUnitType] = useState(unit.roadUsageUnitType)
	const [usageUnit, setUsageUnit] = useState(unit.roadUsageUnit)
	const [weight, setWeight] = useState(unit.weight)
	const [usagePurpose, setUsagePurpose] = useState<UsagePurposeType>(unit.roadUsageUnitPurpose)
	const [rowColor, setRowColor] = useState("#ffffff")
	const [isDeletable, setIsDeletable] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [oldUsageUnitType, setoldUsageUnittype] = useState(unit.roadUsageUnitType)
	const [selectedIcon, setSelectedIcon] = useState<string>("")

	const [updateRoadUsageUnit] = useMutation(UPDATE_ROAD_USAGE_UNIT, {
		onCompleted: () => {
			console.log(" ------------- ROAD USAGE UNIT UPDATED ----------- ")
			usageUnitsRefetch()
		}
	})

	const [updateUnitizationRoadUsageUnit] = useMutation(UNITIZATION_ROAD_USAGE_UNIT_MUTATIONS, {
		onCompleted: () => {
			console.log(" ------------- UNITIZATION ROAD USAGE UNIT UPDATED ----------- ")
			usageUnitsRefetch()
		}
	})

	const [deleteRoadUsageUnit] = useMutation(DELETE_ROAD_USAGE_UNIT)

	useEffect(() => {
		// console.log(unit.establishmentsByRoadUsageUnitId.totalCount)
		if (unit.establishmentsByRoadUsageUnitId.totalCount == 0) {
			setIsDeletable(true)
			setRowColor("#f7000021")
		}
		if (unit.iconImageName) {
			setSelectedIcon(unit.iconImageName)
		}
	}, [])

	const handleCloseUpdateUsageUnit = () => {
		setOpenEdit(false)
	}
	const handleIconChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		console.log(event.target.value)
		setSelectedIcon(event.target.value as string)
	}

	const handleDeleteRoadUsageUnit = () => {
		// toast.loading("Poistetaan tienkäyttöyksikköä", { role: "global", toastId: "usageUnitDelete" })
		updateUnitizationRoadUsageUnit({
			variables: {
				id: unit.id,
				roadUsageUnitPurpose: null,
				roadUsageUnitType: null,
				weight: null,
				roadUsageUnit: null,
				oldUnitType: oldUsageUnitType,
				type: "delete",
				from: "home",
				unitizationRoadUsageUnitId: null,
				roadUsageUnitIconName: null
			},
			onCompleted(data, clientOptions?) {
				// toast.update("usageUnitDelete", {
				// 	render: "Tienkäyttöyksikkö poistettu",
				// 	isLoading: false,
				// 	type: toast.TYPE.SUCCESS,
				// 	closeOnClick: true,
				// 	autoClose: 5000,
				// })
				usageUnitsRefetch()
			},

			onError(error, clientOptions?) {
				// toast.update("usageUnitDelete", {
				// 	render: "Tienkäyttöyksikön poisto epäonnistui",
				// 	isLoading: false,
				// 	type: toast.TYPE.ERROR,
				// 	closeOnClick: true,
				// 	autoClose: 5000,
				// })
				null
			}
		})
			.then((res) => {
				console.log("Onnistui")
			})
			.catch((error) => {
				console.error("Virhe tapahtui:", error)
				toast.error(
					"Liikennelajia ei onnistuttu poistamaan yksiköintityökalusta. Käy poistamassa se manuaalisesti kohdasta 'Yksiköintityökalut'=>'Hallinnoi liikennelajeja'"
				)
			})
	}

	const handleUpdateUsageUnit = () => {
		console.log(usageUnit)
		updateUnitizationRoadUsageUnit({
			variables: {
				id: unit.id,
				roadUsageUnitPurpose: usagePurpose,
				roadUsageUnitType: usageUnitType,
				weight: weight,
				roadUsageUnit: usageUnit,
				oldUnitType: oldUsageUnitType,
				type: "update",
				from: "home",
				roadUsageUnitIconName: selectedIcon
			}
		})
			.then((res) => {
				setoldUsageUnittype(usageUnitType)
			})
			.catch((error) => {
				console.error("Virhe tapahtui:", error)
				toast.error(
					"Liikennelajia ei onnistuttu päivittämään yksiköintityökaluun. Käy päivittämässä se manuaalisesti kohdasta 'Yksiköintityökalut'=>'Hallinnoi liikennelajeja'"
				)
			})

		setOpenEdit(false)
	}

	const handleSelect = (v: UsagePurposeType) => {
		console.log(v)
		setUsagePurpose(v)
	}
	const handleOpenEdit = () => {
		setOpenEdit(true)
		console.log(oldUsageUnitType)
	}

	return (
		<>
			<Dialog open={openEdit} maxWidth={"xs"} fullWidth>
				<DialogCloseButton closeFunction={() => setOpenEdit(false)} />
				<CustomDialogTitle>Liikennelajin muokkaus</CustomDialogTitle>
				{isDeletable ? <DeleteChip deleteFunction={() => setOpenDelete(true)} /> : null}
				<DialogContent>
					<Stack direction="column" sx={{ textAlign: "center", alignContent: "center" }}>
						<TextField
							label="Liikennelaji"
							value={usageUnitType}
							onChange={(e: { target: { value: string } }) => setUsageUnitType(e.target.value)}
						/>
						<TextField label="Lyhenne" value={usageUnit} onChange={(e: { target: { value: string } }) => setUsageUnit(e.target.value)} />
						<MonetaryInput label="Painoluku (t)" value={weight} valueSetter={(v: number) => setWeight(v)} />
						<TextField
							select
							label="Verotuslaji"
							value={usagePurpose ?? 0}
							sx={{ textAlign: "left" }}
							onChange={(e: { target: { value: UsagePurposeType | 0 } }) => handleSelect(e.target.value === 0 ? null : e.target.value)}
						>
							<MenuItem value={0}>Yksityiskäyttö</MenuItem>
							<MenuItem value={"BUSINESS"}>Yritystoiminta</MenuItem>
							<MenuItem value={"AGRICULTURE"}>Maatalous</MenuItem>
							<MenuItem value={"FORESTRY"}>Metsätalous</MenuItem>
						</TextField>
						<FormControl size="small" border sx={{ paddingTop: "5px" }}>
							<InputLabel id="icon-select-label" sx={{ left: "5px", paddingTop: "12px" }}>
								Kuvake
							</InputLabel>

							<Select
								labelId="icon-select-label"
								label="Kuvake"
								value={selectedIcon ? selectedIcon : ""}
								onChange={handleIconChange}
								sx={{ borderRadius: "10px", width: "98%", left: "4px", minHeight: "55px", paddingTop: "5px" }}
								MenuProps={{
									PaperProps: {
										style: {
											maxHeight: "35%", // Maksimikorkeus
											overflowY: "auto" // Pystysuuntainen rullaus
										}
									}
								}}
							>
								{[...icons, { fileName: "", image: "" }].map((item: Icon) => (
									<MenuItem key={item.fileName} value={item.fileName}>
										{item.fileName !== "" ? (
											<Box display="flex" alignItems="center" width="40px">
												<img src={item.image} alt={item.fileName} style={{ width: 50, height: "auto", marginRight: 8 }} />
												<Typography>{selectIconDescription(item.fileName)}</Typography>
											</Box>
										) : (
											"Tyhjennä"
										)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
				</DialogContent>
				<CustomDialogActions>
					<Button variant="outlined" fullWidth sx={{ maxWidth: "100px" }} onClick={handleCloseUpdateUsageUnit}>
						Sulje
					</Button>
					<Button variant="contained" fullWidth sx={{ maxWidth: "100px" }} onClick={handleUpdateUsageUnit}>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>

			<NotificationDialog
				dialogTitle={"Liikennelajin poisto"}
				openState={openDelete}
				setOpenState={setOpenDelete}
				executeButtonText={"Poista"}
				executefunction={handleDeleteRoadUsageUnit}
			>
				Liikennelaji ei ole käytössä. Haluatko poistaa sen?
			</NotificationDialog>

			<TableRow
				sx={{
					backgroundColor: rowColor
				}}
			>
				<TableCell>
					<Typography
						sx={{
							fontSize: "0.9rem",
							fontWeight: "500"
						}}
					>
						{unit.roadUsageUnitType}
					</Typography>
				</TableCell>
				<TableCell>
					{
						<Box display="flex" alignItems="center" width="40px">
							<img
								src={icons.find((item: Icon) => item.fileName === selectedIcon)?.image}
								style={{ width: 50, height: "auto", marginRight: 8 }}
								alt={selectedIcon}
							/>
						</Box>
					}
				</TableCell>
				<TableCell>
					<Typography
						sx={{
							fontSize: "0.9rem",
							fontWeight: "500"
						}}
					>
						{unit.roadUsageUnit}
					</Typography>
				</TableCell>
				<TableCell>
					<Typography
						sx={{
							fontSize: "0.9rem",
							fontWeight: "500"
						}}
					>
						{unit.weight}
					</Typography>
				</TableCell>
				<TableCell sx={{ textAlign: "center" }}>
					{
						{
							FORESTRY: (
								<Tooltip title="Metsätalous">
									<ForestIcon />
								</Tooltip>
							),
							AGRICULTURE: (
								<Tooltip title="Maatalous">
									<AgricultureIcon />
								</Tooltip>
							),
							BUSINESS: (
								<Tooltip title="Yritystoiminta">
									<BusinessIcon />
								</Tooltip>
							),
							PRIVATE: (
								<Tooltip title="Yksityiskäyttö">
									<HomeIcon />
								</Tooltip>
							)
						}[unit.roadUsageUnitPurpose ?? "PRIVATE"]
					}
				</TableCell>
				<TableCell>
					<IconButton
						// color='primary'
						size="small"
						onClick={handleOpenEdit}
					>
						<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
					</IconButton>
				</TableCell>
			</TableRow>
		</>
	)
}

export default RoadUsageUnitsTableRow
