import maplibregl from "maplibre-gl"
import { MapProviderDispatch, mapProviderStateType } from "../../utils/providers/mapProvider/mapProvider"
import { cardClasses } from "@mui/material"

export const setCostCenterBoundsAndVisibility = (
	map: MapInstance,
	selectedCostCenterId: number | undefined,
	allRCGeoData: Record<string, any>,
	setBounds: React.Dispatch<React.SetStateAction<maplibregl.LngLatBounds | null | undefined>>,
	state: mapProviderStateType,
	dispatch: MapProviderDispatch
) => {
	let bounds = null
	const unFeaturedLayers: string[] = []
	console.log(selectedCostCenterId)
	if (selectedCostCenterId === undefined) {
		const cc = allRCGeoData?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId?.nodes?.find(
			(costCenter: any) => costCenter?.roadLinksByCostCenterId?.nodes?.length > 0
		)

		if (!cc) {
			console.warn("Ei löytynyt CostCenteriä, jolla on roadlinkkejä!")
			return
		}

		// 2. Kerää kaikki roadlinkien pisteet yhteen taulukkoon
		let allCoordinates: { lat: number; lng: number }[] = []
		cc.roadLinksByCostCenterId.nodes.forEach((roadLink: any) => {
			// Oletus: roadLink.coordinates on taulukko pisteistä muodossa { lat, lng }
			allCoordinates = allCoordinates.concat(roadLink.coordinates)
		})

		// 3. Selvitä min/max lat & lng
		let minLat = Infinity
		let maxLat = -Infinity
		let minLng = Infinity
		let maxLng = -Infinity

		allCoordinates.forEach((coord) => {
			if (coord.lat < minLat) minLat = coord.lat
			if (coord.lat > maxLat) maxLat = coord.lat
			if (coord.lng < minLng) minLng = coord.lng
			if (coord.lng > maxLng) maxLng = coord.lng
		})

		// 4. Luo bounds näiden ääriarvojen perusteella
		bounds = new maplibregl.LngLatBounds({ lat: minLng, lng: minLat }, { lat: maxLng, lng: maxLat })
	} else {
		for (let i = 0, len = allRCGeoData?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
			const cc = allRCGeoData?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes[i]
			if (selectedCostCenterId === cc.id || selectedCostCenterId === undefined) {
				let coordinates
				if (cc.roadLinksByCostCenterId.nodes.length > 0) {
					coordinates = cc.roadLinksByCostCenterId.nodes[0].coordinates
					if (selectedCostCenterId === cc.id) {
						bounds = new maplibregl.LngLatBounds(
							{ lat: coordinates[0].lng, lng: coordinates[0].lat },
							{ lat: coordinates[0].lng, lng: coordinates[0].lat }
						)
					}
				} else {
					const properties = cc.propertiesByCostCenterId.nodes

					let allCoordinates: any[] = []

					properties.forEach((property: any) => {
						const plotId = property.plotId

						const sourceFeatures = map.current.querySourceFeatures("kipa", {
							sourceLayer: "PalstanSijaintitiedot"
						})

						const foundProperty = sourceFeatures.find((feature: any) => {
							return feature.id === plotId
						})

						if (!foundProperty) {
							console.warn("Ominaisuutta ei löydy annettulla id:llä:", plotId)
							return
						}

						const polygonCoordinates = foundProperty.geometry.coordinates

						const latLngPairs = polygonCoordinates[0].map((coord: any) => {
							return { lat: coord[1], lng: coord[0] }
						})

						allCoordinates = allCoordinates.concat(latLngPairs)
					})

					let minLat = Infinity
					let maxLat = -Infinity
					let minLng = Infinity
					let maxLng = -Infinity

					allCoordinates.forEach((coord) => {
						if (coord.lat < minLat) minLat = coord.lat
						if (coord.lat > maxLat) maxLat = coord.lat
						if (coord.lng < minLng) minLng = coord.lng
						if (coord.lng > maxLng) maxLng = coord.lng
					})

					bounds = new maplibregl.LngLatBounds([minLng, minLat], [maxLng, maxLat])
				}

				map.current.setLayoutProperty(`costCenterRoadLinks${cc.name}`, "visibility", "visible")
				for (const lineString of cc.roadLinksByCostCenterId.nodes) {
					for (const coord of lineString.coordinates) {
						bounds?.extend([coord.lat, coord.lng])
					}
				}
			} else {
				unFeaturedLayers.push(`costCenterRoadLinks${cc.name}`)
				map.current.setLayoutProperty(`costCenterRoadLinks${cc.name}`, "visibility", "none")
			}
		}
	}
	const tempMapLayers = [...state.mapLayers]
	for (let i = 0, len = tempMapLayers.length; len > i; i++) {
		if (unFeaturedLayers.includes(tempMapLayers[i].name)) {
			tempMapLayers[i].featured = false
		} else {
			tempMapLayers[i].featured = true
		}
	}
	dispatch({ type: "setMapLayers", payload: tempMapLayers })
	setBounds(bounds)
}
