import React, { useState } from "react"
import { toast } from "react-toastify"

/**
 * LoginFormProps:
 *  - setOpenForgetPasswordDialog: avaa unohtuneen salasanan dialogin
 *  - loginUser: dataService-funktio, joka yrittää kirjautumista backendissä
 */
interface LoginFormProps {
	setOpenForgetPasswordDialog: React.Dispatch<React.SetStateAction<boolean>>
	loginUser: (email: string, password: string) => Promise<any>
}

const LoginForm = ({ setOpenForgetPasswordDialog, loginUser }: LoginFormProps) => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")

	/**
	 * Hyvin yksinkertainen email-check
	 */
	const validateEmail = (value: string) => /\S+@\S+\.\S+/.test(value)

	/**
	 * Palauttaa mahdolliset virheilmoitukset
	 */
	const checkLoginEventValues = () => {
		const errors: string[] = []
		if (!email || !validateEmail(email)) {
			errors.push("Tarkista sähköpostin oikeinkirjoitus tai syötä sähköposti.")
		}
		if (!password) {
			errors.push("Salasana puuttuu.")
		}
		return errors
	}

	/**
	 * Käsittelee lomakkeen submitin:
	 * - tarkistaa lomakkeen validiuden
	 * - kutsuu loginUser
	 * - näyttää toastit
	 */
	const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		// Lomakkeen kenttien validointi
		const errors = checkLoginEventValues()
		if (errors.length > 0) {
			errors.forEach((error) => toast.error(error))
			return
		}

		try {
			await loginUser(email, password)
			toast.success("Kirjautuminen onnistui!")
			window.location.reload()
		} catch (err: any) {
			if (err.message === "NETWORK_ERROR") {
				toast.error("Yhteyden muodostaminen palvelimelle epäonnistui. Yritä myöhemmin uudelleen.")
			} else if (err.message === "USER_NOT_VERIFIED") {
				toast.error("Tiliä ei ole vahvistettu. Tarkista sähköpostisi vahvistuslinkki.")
			} else if (err.message === "WRONG_CREDENTIALS") {
				toast.error("Virhe kirjautumisessa. Sähköposti tai salasana väärin.")
			} else {
				toast.error("Kirjautuminen epäonnistui. Tarkista tiedot ja yritä uudelleen.")
			}
		}
	}

	return (
		<div className="login-form">
			<div className="loginFormTitle">Kirjaudu</div>

			<form onSubmit={handleFormSubmit}>
				<input type="text" className="loginFormInput" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Sähköposti" />

				<input
					type="password"
					className="loginFormInput"
					name="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Salasana"
				/>

				<button type="submit" className="loginFormButton">
					Kirjaudu
				</button>
			</form>

			<div className="login-form-footer">
				<span style={{ cursor: "pointer" }} onClick={() => setOpenForgetPasswordDialog(true)}>
					Unohditko salasanasi?
				</span>
			</div>
		</div>
	)
}

export default LoginForm
