import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from "@apollo/client"
import {
	Typography,
	Stack,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	TableHead,
	Table,
	Paper,
	CircularProgress,
	Chip,
	Dialog,
	DialogContent,
	Button,
	TextField,
	Box,
	MenuItem
} from "@mui/material"
import React, { useEffect, useState } from "react"
import RoadUsageUnitsTableRow from "./RoadUsageUnitsTableRow"
import AddIcon from "@mui/icons-material/Add"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import MonetaryInput from "../../../utils/MonetaryInput"
import { toast } from "react-toastify"
import { FormControl } from "@mui/material"
import { InputLabel } from "@mui/material"
import { Select } from "@mui/material"
import { selectIconDescription } from "../../reusables/CommonFunctions"
import InfoButtonPaper from "../../reusables/InfoButtonPaper"
import { RoadUsageUnitText } from "../../reusables/InfoTexts"

const GET_ALL_ROAD_USAGE_UNITS = gql`
	query GetAllRoadUsageUnits {
		roadCooperativeWithJWT {
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					id
					roadUsageUnit
					roadUsageUnitType
					roadUsageUnitPurpose
					weight
					establishmentsByRoadUsageUnitId {
						totalCount
					}
					roadUsageUnitDescription
					iconImageName
				}
			}
		}
	}
`

const CREATE_ROAD_USAGE_UNIT = gql`
	mutation MyMutation2(
		$roadCooperativeId: UUID
		$roadUsageUnit: String = ""
		$roadUsageUnitType: String = ""
		$roadUsageUnitPurpose: UsageUnitPurposeType
		$weight: Float = 1.5
	) {
		createRoadUsageUnit(
			input: {
				roadUsageUnit: {
					roadCooperativeId: $roadCooperativeId
					roadUsageUnit: $roadUsageUnit
					roadUsageUnitType: $roadUsageUnitType
					roadUsageUnitPurpose: $roadUsageUnitPurpose
					weight: $weight
				}
			}
		) {
			clientMutationId
		}
	}
`

export const UNITIZATION_ROAD_USAGE_UNIT_MUTATIONS = gql`
	mutation UnitizationRoadUsageUnitMutations(
		$weight: Float = 1.5
		$roadUsageUnitType: String = ""
		$roadUsageUnitPurpose: UsageUnitPurposeType
		$roadUsageUnit: String = ""
		$oldUnitType: String
		$type: String
		$from: String
		$unitizationRoadUsageUnitId: Int
		$id: Int
		$unitizationId: Int
		$roadUsageUnitIconName: String = null
	) {
		updateUnitizationRoadUsageUnitExtended(
			input: {
				unitizationRoadUsageUnitPatch: {
					roadUsageUnitPurpose: $roadUsageUnitPurpose
					roadUsageUnitType: $roadUsageUnitType
					roadUsageUnitIconName: $roadUsageUnitIconName
					weight: $weight
					roadUsageUnit: $roadUsageUnit
					unitizationId: $unitizationId
				}
				oldUnitType: $oldUnitType
				type: $type
				from: $from
				unitizationRoadUsageUnitId: $unitizationRoadUsageUnitId
				id: $id
			}
		) {
			clientMutationId
		}
	}
`

interface Icon {
	fileName: string
	image: string // tai jokin muu tarvittava tyyppi
}

const RoadUsageUnitsTable = () => {
	const [usageUnitsState, setUsageUnitState] = useState<any>()
	const [openCreateRoadUsageUnit, setOpenCreateRoadUsgeUnit] = useState<boolean>(false)
	const [usageUnitType, setUsageUnitType] = useState("")
	const [usageUnit, setUsageUnit] = useState("")
	const [weight, setWeight] = useState(0)
	const [usagePurpose, setUsagePurpose] = useState<UsagePurposeType>(null)
	const currentUserRC = userProfileStorage({ type: "getActiveRoadCooperative" })
	const [selectedIcon, setSelectedIcon] = useState<string>("")
	const [icons, setIcons] = useState<any>()

	const [selectedMember, setSelectedMember] = useState<number>()

	const handleCloseCreateUsageUnit = () => {
		setOpenCreateRoadUsgeUnit(false)
		setUsageUnit("")
		setUsageUnitType("")
		setWeight(0)
	}

	const {
		data: roadUsageUnits,
		loading: roadUsageUnitsLoading,
		error: roadUsageUnitsError,
		refetch: roadUsageUnitsRefetch
	} = useQuery(GET_ALL_ROAD_USAGE_UNITS, {
		onCompleted: (data) => {
			console.log(data)
		}
	})

	const [createRoadUsageUnit] = useMutation(CREATE_ROAD_USAGE_UNIT, {
		onCompleted: () => {
			console.log(" --------------------- ROAD USAGE UNIT CREATED -------------")

			roadUsageUnitsRefetch()
		}
	})

	const [createUnitizationRoadUsageUnit] = useMutation(UNITIZATION_ROAD_USAGE_UNIT_MUTATIONS, {
		onCompleted: () => {
			console.log(" ------------- UNITIZATION ROAD USAGE UNIT UPDATED ----------- ")
			roadUsageUnitsRefetch()
		}
	})

	const {
		data: usageUnitsData,
		loading: usageUnitsLoading,
		error: usageUnitsError,
		refetch: usageUnitsRefetch
	} = useQuery(GET_ALL_ROAD_USAGE_UNITS, {
		onCompleted: (data) => {
			null
			// console.log(data, " ---------- ")
		}
	})

	const handleCreateRoadUsageUnit = () => {
		// createRoadUsageUnit({
		// 	variables: {
		// 		roadUsageUnit: usageUnit,
		// 		roadUsageUnitType: usageUnitType,
		// 		weight: weight,
		// 		roadUsageUnitPurpose: usagePurpose,
		// 		roadCooperativeId: currentUserRC.id,
		// 	},
		// })
		createUnitizationRoadUsageUnit({
			variables: {
				roadUsageUnitPurpose: usagePurpose,
				roadUsageUnitType: usageUnitType,
				weight: weight,
				roadUsageUnit: usageUnit,
				oldUnitType: null,
				type: "create",
				from: "home",
				unitizationRoadUsageUnitId: null,
				roadCooperativeId: currentUserRC.id,
				roadUsageUnitIconName: selectedIcon
			}
		}).catch((error) => {
			console.error("Virhe tapahtui:", error)

			toast.error(
				"Liikennelajia ei onnistuttu Lisäämään yksiköintityökaluun. Käy lisäämässä se manuaalisesti kohdasta 'Yksiköintityökalut'=>'Hallinnoi liikennelajeja'"
			)
		})
		setOpenCreateRoadUsgeUnit(false)
		setUsageUnit("")
		setUsageUnitType("")
		setWeight(0)
	}

	const handleSelect = (v: UsagePurposeType) => {
		setUsagePurpose(v)
	}

	useEffect(() => {
		if (usageUnitsData)
			setUsageUnitState(
				[...usageUnitsData.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes].sort((a: RoadUsageUnit, b: RoadUsageUnit) => {
					return a.roadUsageUnitType.localeCompare(b.roadUsageUnitType) // Muutoin lajittelee normaalisti
				})
			)
	}, [usageUnitsData, usageUnitsRefetch])

	function importAll() {
		const images = (require as any).context("../../../resources/roadUsageUnitIcons", false)

		const imageList = images.keys().map((filePath: string) => {
			return {
				fileName: filePath.replace("./", ""), // Poistetaan "./"
				image: images(filePath) // Webpackin generoima URL
			}
		})

		return imageList
	}

	const handleIconChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedIcon(event.target.value as string)
	}

	useEffect(() => {
		const loadedIcons = importAll()

		setIcons(loadedIcons)
	}, [])

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginBottom: "5px", paddingLeft: "15px", paddingRight: "15px" }}>
				<Stack direction="row">
					<Typography sx={{ fontSize: "1.2rem" }}>Tienkäytön perusteet</Typography>
					<InfoButtonPaper
						infoIconSx={{ bottom: "5px" }}
						text={<RoadUsageUnitText></RoadUsageUnitText>}
						pointerDirection={"top"}
						subclass={"highlight"}
					></InfoButtonPaper>
				</Stack>

				<Chip icon={<AddIcon />} label="Lisää" variant="contained" color="primary" onClick={() => setOpenCreateRoadUsgeUnit(true)} />
			</Stack>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ fontWeight: "700" }}>Liikennelaji</TableCell>
							<TableCell sx={{ fontWeight: "700" }}>Kuvake</TableCell>
							<TableCell sx={{ fontWeight: "700" }}>Lyhenne</TableCell>
							<TableCell sx={{ fontWeight: "700" }}>Painoluku (t)</TableCell>
							<TableCell sx={{ width: "30px" }}></TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{!usageUnitsState
							? null
							: usageUnitsState.map((unit: any) => {
									return (
										<React.Fragment key={unit.id}>
											<RoadUsageUnitsTableRow unit={unit} usageUnitsRefetch={usageUnitsRefetch} icons={icons} />
										</React.Fragment>
									)
								})}
					</TableBody>
				</Table>
			</TableContainer>
			{usageUnitsLoading ? <CircularProgress /> : null}
			{usageUnitsError ? <>error</> : null}

			<Dialog open={openCreateRoadUsageUnit} maxWidth={"xs"} fullWidth>
				<DialogCloseButton closeFunction={() => setOpenCreateRoadUsgeUnit(false)} />
				<CustomDialogTitle>Luo liikennelaji</CustomDialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column"
						}}
					>
						<TextField onChange={(e: { target: { value: string } }) => setUsageUnitType(e.target.value)} label="Liikennelaji" />
						<TextField onChange={(e: { target: { value: string } }) => setUsageUnit(e.target.value)} label="Lyhenne" />
						<MonetaryInput maximumDecimals={2} valueSetter={(v: number) => setWeight(v)} label="Painoluku (tonnia)" value={0} />
						<TextField
							select
							label="Verotuslaji"
							value={usagePurpose ?? 0}
							sx={{ textAlign: "left" }}
							onChange={(e: { target: { value: UsagePurposeType | 0 } }) => handleSelect(e.target.value == 0 ? null : e.target.value)}
						>
							<MenuItem value={0}>Yksityiskäyttö</MenuItem>
							<MenuItem value={"BUSINESS"}>Yritystoiminta</MenuItem>
							<MenuItem value={"AGRICULTURE"}>Maatalous</MenuItem>
							<MenuItem value={"FORESTRY"}>Metsätalous</MenuItem>
						</TextField>
						{icons ? (
							<FormControl size="small" sx={{ paddingTop: "5px" }}>
								<InputLabel id="icon-select-label" sx={{ left: "5px", paddingTop: "12px" }}>
									Kuvake
								</InputLabel>

								<Select
									labelId="icon-select-label"
									label="Kuvake"
									value={selectedIcon ? selectedIcon : ""}
									onChange={handleIconChange}
									sx={{ borderRadius: "10px", width: "98%", left: "4px", minHeight: "55px", paddingTop: "5px" }}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: "35%", // Maksimikorkeus
												overflowY: "auto" // Pystysuuntainen rullaus
											}
										}
									}}
								>
									{[...icons, { fileName: "", image: "" }].map((item: Icon) => (
										<MenuItem key={item.fileName} value={item.fileName}>
											{item.fileName !== "" ? (
												<Box display="flex" alignItems="center" width="40px">
													<img src={item.image} alt={item.fileName} style={{ width: 50, height: "auto", marginRight: 8 }} />
													<Typography>{selectIconDescription(item.fileName)}</Typography>
												</Box>
											) : (
												"Tyhjennä"
											)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						) : null}
					</Box>
				</DialogContent>
				<CustomDialogActions>
					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="outlined"
						onClick={handleCloseCreateUsageUnit}
					>
						Sulje
					</Button>

					<Button
						sx={{
							maxWidth: "120px"
						}}
						fullWidth
						variant="contained"
						onClick={handleCreateRoadUsageUnit}
					>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>
		</>
	)
}

export default RoadUsageUnitsTable
