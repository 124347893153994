import React from "react"
import { Link } from "react-router-dom"

const RoadLinkText = () => {
	return (
		<div>
			<b>Tien osat</b>
			<br />
			Tiekunnan tietä voidaan jakaa eri osiin, joita kutsutaan tienosiksi. Näitä voi tarkastella erikseen Tievahdin karttanäkymässä tai käsitellä
			erillisinä talouskohteina, jos tiekunnalla on tarvetta esimerkiksi kustannusten tai korjausveloitusten erittelyyn.
			<br />
			Jos sinulla on muokkausoikeudet, voit luoda uusia tienosia Tievahdin pääsivulta &apos;AVAA&apos;-painikkeen kautta. Valitse sitten &apos;Lisää
			tie&apos; ja täytä halutut tiedot. Tienosia voi myöhemmin muokata tai poistaa asetuksista.
		</div>
	)
}

const RoadUsageUnitText = () => {
	return (
		<div>
			<b>Liikennelajit</b>
			<br />
			Tienkäyttö on jaoteltu erilaisiin liikennelajeihin, joille on määritetty painokertoimet (ns. yksiköt) tien rasittavuuden perusteella. Esimerkiksi
			henkilöautolla on tyypillisesti pienempi kerroin kuin raskaalla kuorma-autolla.
			<br />
			Käytettävissä ovat valmiit Maanmittauslaitoksen ohjeistuksen mukaiset liikennelajit, mutta voit myös määrittää omia liikennelajeja tai muokata
			kertoimia tarpeiden mukaan. Tievahdin kartalla liikennelajeja voi edustaa eri kuvakkeilla, jotta ne on helppo tunnistaa.
		</div>
	)
}

const PermissionInfoText = () => {
	return (
		<div>
			<b>Osakkaan käyttöoikeudet</b>
			<br />- <b>Katseluoikeudet:</b> Käyttäjä näkee vain tiekunnan perustiedot, kuten kartan, rajoitetut tiedot muista osakkaista ja dokumentit, mutta ei
			voi muokata niitä. Käyttäjä voi muokata omia tiekuntakohtaisia yhteystietojaan Profiili -osion alta.
			<br />- <b>Muokkausoikeudet:</b> Käyttäjä pystyy muokkaamaan tiekunnan asetuksia, kiinteistö- ja omistustietoja sekä muiden käyttäjien oikeuksia.
			Muokkausoikeuksilla on laajat ylläpitovaltuudet verrattuna katseluoikeuksiin.
		</div>
	)
}

const FinancesBasicText = () => {
	return (
		<div>
			<b>Talouden oletukset</b>
			<br />
			Voit määrittää tiekunnan taloushallinnan oletusasetuksia, kuten oletustiliä ja maksuehtoja.
		</div>
	)
}

const IncomeAndOutcomeCategoriesText = () => {
	return (
		<div>
			<b>Tulo- ja menoluokat</b>
			<br />
			Taloushallinnossa voit määrittää erilaisia tulo- ja menoluokkia. Jokainen tulo- tai menotapahtuma kohdistetaan yhteen näistä luokista, ja ne näkyvät
			tiekunnan kirjanpidossa.
			<br />
			Huomioithan, että et voi poistaa luokkaa, jos siihen on jo kohdistettu tilitapahtumia. Tarvittaessa voit kuitenkin muokata luokan nimeä tai luoda
			uusia luokkia vastaamaan tiekunnan muuttuvia tarpeita.
		</div>
	)
}

const RoadPaymentsText = () => {
	return (
		<div>
			<b>Tiemaksut</b>
			<br />
			Tänne kertyvät tiekunnalle luodut laskut, jotka ovat vielä avoimia. Uuden laskun voit luoda &quot;Luo lasku&quot; -painikkeen kautta tai
			taloussuunnitelmassa. Kun lasku on maksettu kokonaan, se siirtyy pois Avoimet-listalta.
			<br />
			Hallinnoi laskuja klikkaamalla niiden riviä. Voit tarvittaessa muokata, hyvittää, muistuttaa tai lähettää laskuja uudelleen.
		</div>
	)
}

const BillableText = () => {
	return (
		<div>
			<b>Laskutettavat (massalaskutus)</b>
			<br />
			Laskutettavat-osiossa voit luoda taloussuunnitelman, jonka avulla generoit massana tiemaksuja ja käyttömaksuja. Prosessi voidaan jakaa kahteen
			vaiheeseen: talousarvion laatiminen (ennuste) ja maksuunpanoluettelon laatiminen (varsinainen laskutus).
			<br />
			Kun maksuunpanoluettelo on valmis, se näkyy &quot;Laskutettavat&quot;-listauksessa, josta voit lähettää laskut. Lähetetyt laskut siirtyvät
			&quot;Tiemaksut&quot;-osioon avoimiksi laskuiksi.
			<br />
			Sekä talousarvio että maksuunpanoluettelo tallentuvat tiekunnan asiakirjoihin.
		</div>
	)
}

const UnCategorizedText = () => {
	return (
		<div>
			<b>Tilitapahtumat (luokittelemattomat)</b>
			<br />
			Luokittelemattomiin kerätään kaikki tilitapahtumat, joita ei ole vielä kohdistettu tietylle tulo- tai menoluokalle. Jos pankkiyhteys on käytössä,
			tapahtumat tulevat automaattisesti Tievahtiin. Ilman automaattista yhteyttä voit lisätä tapahtumia manuaalisesti &quot;Lisää tilitapahtuma&quot;
			-napista.
			<br />
			Tapahtuman luokittelu poistaa sen luokittelemattomien listalta, mutta itse tapahtuma löytyy yhä päälistauksesta. Voit kohdistaa tapahtuman myös
			suoraan tietylle laskulle tai jakaa sen usealle luokalle. Tositteiden lisäys on myös mahdollista.
			<br />
			Hallitse tulo- ja menoluokkia &nbsp;
			<Link to="/options/finances" style={{ color: "#7FC646", textDecoration: "underline" }}>
				Asetukset
			</Link>
			-sivun kautta.
		</div>
	)
}

const DeptText = () => {
	return (
		<div>
			<b>Velat</b>
			<br />
			Tänne voit merkitä tiekunnan lainat, toimituskulut tai muut mahdolliset velat. Velka kirjataan mukaan tiekunnan varallisuuslaskelmaan omana eränään.
			Näin pysyt ajan tasalla siitä, millaisia sitoutuneita kuluja tiekunnalla on.
		</div>
	)
}

const PropertyText = () => {
	return (
		<div>
			<b>Omaisuus</b>
			<br />
			Tähän voit kirjata tiekunnan omaisuuden, esimerkiksi murskeen, kyltit, suolan, rumpuputket tai mahdolliset koneet. Omaisuus näkyy tiekunnan
			varallisuuslaskelmassa omana eränään. Näin tiekunnan kokonaisvarallisuus saadaan kootusti esille, myös irtaimen omaisuuden osalta.
		</div>
	)
}

const ControllingFinanceAccounts = () => {
	return (
		<div>
			<b>Pankkitilien hallinta</b>
			<br />
			Hallitse tiekunnan käyttämiä pankkitilejä, jotta Tievahdin talousosio tietää, mistä tilitapahtumat haetaan. Voit lisätä tilejä sekä päivittää
			olemassa olevien tiliyhteyksien voimassaoloaikaa.
		</div>
	)
}

const ExternalOperatorsText = () => {
	return (
		<div>
			<b>Ulkoiset toimijat</b>
			<br />
			Tähän voit lisätä esimerkiksi tieisännöitsijän tai muita yhteistyötahoja, joilta tiekunta tilaa palveluita tai joille tiekunta myy palveluita. Voit
			määrittää ulkoiselle toimijalle katselu- tai muokkausoikeudet. Ne toimivat samalla periaatteella kuin osakkaiden katselu- ja muokkausoikeudet.
		</div>
	)
}

const BasicRcSettings = () => {
	return (
		<div>
			<b>Tiekunnan perustiedot</b>
			<br />
			Tässä osiossa hallitaan tiekunnan yleisiä tietoja, kuten nimi, käyttöoikeusyksikkötunnus, y-tunnus ja kunta. Käyttöoikeusyksikkötunnus on tunnus,
			jonka voit selvittää{" "}
			<a
				href="https://storymaps.arcgis.com/stories/04cefb6993254e5ab4fdec5bc0f129cb"
				target="_blank"
				rel="noopener noreferrer"
				style={{ textDecoration: "underline", color: "#7FC646" }}
			>
				Metsäkeskuksen karttapalvelusta
			</a>
			.
		</div>
	)
}

const RcContactsSettings = () => {
	return (
		<div>
			<b>Tiekunnan yhteystiedot</b>
			<br />
			Täällä hallinnoidaan tiekunnan yhteystietoja. Voit erotella yleisen yhteystiedon ja talouteen liittyvän yhteystiedon (joka näkyy esim. laskuilla
			yhteyshenkilönä). <br />
		</div>
	)
}

export {
	RoadLinkText,
	RoadUsageUnitText,
	PermissionInfoText,
	FinancesBasicText,
	IncomeAndOutcomeCategoriesText,
	RoadPaymentsText,
	BillableText,
	UnCategorizedText,
	DeptText,
	PropertyText,
	ControllingFinanceAccounts,
	ExternalOperatorsText,
	BasicRcSettings,
	RcContactsSettings
}
