import React, { useEffect, useState } from "react"
import { Button } from "@mui/material"
import readXlsxFile from "read-excel-file"
import { Fab } from "@mui/material"
import { Box, Paper } from "@mui/material"
import { Stack } from "@mui/material"

import GroupAddIcon from "@mui/icons-material/GroupAdd"
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { TableContainer } from "@mui/material"
import { Table } from "@mui/material"
import { TableHead } from "@mui/material"
import { TableRow } from "@mui/material"
import { TableCell } from "@mui/material"
import { Typography } from "@mui/material"
import { TableBody } from "@mui/material"
import MemberRow from "./BulkImportMemberRow"
import { gql, useMutation, useQuery } from "@apollo/client"
import { toast } from "react-toastify"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import { v4 } from "uuid"
import { Backdrop } from "@mui/material"
import { CircularProgress } from "@mui/material"
import "./BulkImportTools.css"
import { getAllCountries } from "../../../network/dataServices/generalDataService"
import PopUpForIntroductionSupport from "../../reusables/PopUpForIntroductionSupport"
import { Grid } from "@mui/material"
import IconLinkButton from "../../reusables/IconLinkButton"

const CREATE_ROAD_COOPERATIVE_MEMBERS = gql`
	mutation CreateRoadCooperativeMembers($input: [SeveralRoadCooperativeMembersInput!]!) {
		createSeveralRoadCooperativeMembers(input: $input)
	}
`

const GET_ALL_MEMBERS = gql`
	query GetAllMembers {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId {
				nodes {
					userEmail
					name
				}
			}
		}
	}
`

interface MembersFile extends HTMLElement {
	files?: File[]
}

type Member = {
	identifier: string
	name: string
	contactPerson: string
	email: string
	telNumber: string
	postcode: string
	address: string
	postalDistrict: string
	countryCode: string
	languageCode: string
	meetingInviteType: number
	invoiceSendingType: number
	businessId: string
	eInvoiceOperator: string
	eInvoiceAddress: string
}

const turnValueToString = (value: any) => {
	if (value === null || value === undefined) {
		return ""
	} else {
		return value.toString()
	}
}

const BulkMemberImportTool = ({
	setOpenBulkMemberDataImport,
	setRoadCooperativeStatusValues
}: {
	setOpenBulkMemberDataImport: React.Dispatch<React.SetStateAction<boolean>>
	setRoadCooperativeStatusValues: React.Dispatch<
		React.SetStateAction<{
			createRoad: boolean
			createMembers: boolean
			createEstablishments: boolean
		}>
	>
}) => {
	// Deactivate window.alert
	window.alert = function () {
		null
	}

	const [allMembers, setAllMembers] = useState<any[]>([])
	const [allCountries, setAllCountries] = useState<Country[]>([])
	const [allEmails, setAllEmails] = useState<string[]>([])
	const [loading, setLoading] = useState(false)

	const [createRoadCooperativeMembers] = useMutation(CREATE_ROAD_COOPERATIVE_MEMBERS)

	useQuery(GET_ALL_MEMBERS, {
		onCompleted: (data) => {
			const members = data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes
			const emails = []
			for (let i = 0, len = members.length; i < len; i++) {
				emails.push(members[i].userEmail)
			}
			setAllEmails(emails)
		}
	})

	const activeRoadCooperative = userProfileStorage({ type: "getActiveRoadCooperative" })

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
	}, [])

	const membersEmailValidationCheck = () => {
		const emailsAlreadyExist = []
		for (let i = 0; allMembers.length > i; i++) {
			if (allEmails.some((email: string) => allMembers[i].email === email)) {
				emailsAlreadyExist.push(allMembers[i])
				toast.warning(`Rivillä ${i + 1} oleva sähköposti "${allMembers[i].email}" on jo tiekunnan osakkaalla käytössä!`, { autoClose: false })
			}
			for (let i2 = 0; allMembers.length > i2; i2++) {
				if (allMembers[i].email === allMembers[i2].email && i != i2 && allMembers[i2].email != "" && allMembers[i].email != "") {
					emailsAlreadyExist.push(allMembers[i])
					const toastId = "toastWarning"
					if (!toast.isActive(toastId)) {
						toast.warning(
							`Rivillä ${i + 1} oleva sähköposti "${allMembers[i].email}" on sama kuin rivillä ${
								i2 + 1
							}! Sähköpostiosoite ei voi olla sama kahdella osakkaalla.`,
							{ toastId, autoClose: false }
						)
					}
				}
			}
		}
		if (emailsAlreadyExist.length > 0) {
			return false
		} else {
			return true
		}
	}

	const memberDataValidation = () => {
		const rowsWithInvalidData = []
		for (let i = 0; allMembers.length > i; i++) {
			const errorMessage = []
			if (allMembers[i].name === "") {
				errorMessage.push("nimi ")
			}
			if (allMembers[i].address === "") {
				errorMessage.push("postiosoite ")
			}
			if (allMembers[i].postalDistrict === "") {
				errorMessage.push("postitoimipaikka ")
			}
			if (allMembers[i].postcode === "") {
				errorMessage.push("postinumero ")
			}
			if (errorMessage.length > 0) {
				rowsWithInvalidData.push([i + 1])
			}
		}
		if (rowsWithInvalidData.length > 0) {
			const errorRowsString = rowsWithInvalidData.reduce((acc: string, val, i) => {
				if (i == 0) return (acc = val.toString())
				acc = acc + ", " + val.toString()
				return acc
			}, "")
			toast.error(`Tietoja puuttuu ${rowsWithInvalidData.length > 1 ? "riveiltä" : "riviltä"}: ${errorRowsString}`, { autoClose: false })
			return false
		} else {
			return true
		}
	}

	const handleCreateNewMembers = async () => {
		setLoading(true)
		// Func that iterate every member and create new members and members billininfos
		// if (!membersEmailValidationCheck()) {
		// 	setLoading(false)
		// 	return
		// }
		if (!memberDataValidation()) {
			setLoading(false)
			return
		}
		const inputArr = []
		for (let i = 0; allMembers.length > i; i++) {
			inputArr.push({
				member: {
					name: allMembers[i].name,
					userEmail: allMembers[i].email === "" ? null : allMembers[i].email
				},
				billingInformation: {
					billingAddress: allMembers[i].address,
					businessId: allMembers[i].businessId,
					contactPerson: allMembers[i].contactPerson,
					countryCode: allMembers[i].countryCode,
					eInvoiceAddress: allMembers[i].eInvoiceAddress,
					eInvoiceOperator: allMembers[i].eInvoiceOperator,
					invoiceSendingType: Number(allMembers[i].invoiceSendingType),
					languageCode: allMembers[i].languageCode,
					meetingInviteType: Number(allMembers[i].meetingInviteType),
					phoneNumber: allMembers[i].telNumber,
					postalDistrict: allMembers[i].postalDistrict,
					postcode: allMembers[i].postcode,
					email: allMembers[i].email === "" ? null : allMembers[i].email
				}
			})
		}
		createRoadCooperativeMembers({
			variables: { input: inputArr },
			onCompleted: () => {
				setLoading(false)
				setRoadCooperativeStatusValues((prevValue) => {
					return { ...prevValue, createMembers: false }
				})
				setOpenBulkMemberDataImport(false)
				// toast.success("Osakkaiden luonti suoritettu onnistuneesti!", { role: "global" })
				// window.location.reload()
			},
			onError: (e) => {
				if (
					e.message.includes("Tiekunnasta löytyy jo käyttäjä sähköpostilla") ||
					e.message.includes("on listassa usealla osakkaalla. Yksi sähköpostiosoite voi olla ainoastaan yhdellä tiekunnan osakkaalla")
				) {
					toast.error(e.message)
				} else {
					console.error("Unexpected error:", e)
				}

				setLoading(false)
				// toast.error("Osakkaiden luonti epäonnistui", { role: "global" })
			}
		})
	}

	const handleReadFile = async () => {
		setLoading(true)
		const membersFile: MembersFile | null = document.getElementById("membersFile")
		if (membersFile?.files?.length == 0) {
			setLoading(false)
			return
		}
		if (membersFile && membersFile.files) {
			try {
				const rows = await readXlsxFile(membersFile.files[0], { sheet: "Osakkaat" })
				rows.splice(0, 1)
				const tempMembers: Member[] = [...allMembers]
				for (let i = 0; rows.length > i; i++) {
					const randomId = v4()
					const newMember: Member = {
						identifier: randomId,
						name: turnValueToString(rows[i][0]),
						contactPerson: turnValueToString(rows[i][1]),
						email: turnValueToString(rows[i][2]),
						telNumber: turnValueToString(rows[i][3]),
						address: turnValueToString(rows[i][4]),
						postcode: turnValueToString(rows[i][5]),
						postalDistrict: turnValueToString(rows[i][6]),
						countryCode: "FI",
						languageCode: "fi",
						meetingInviteType: 1,
						invoiceSendingType: 1,
						businessId: turnValueToString(rows[i][7]),
						eInvoiceOperator: "",
						eInvoiceAddress: ""
					}
					tempMembers.push(newMember)
				}
				setAllMembers(tempMembers)
			} catch (error) {
				toast.error("Virhe luettaessa tiedostoa", { role: "global" })
				setLoading(false)
			}
		} else {
			setLoading(false)
			return
		}
		setLoading(false)
	}

	const handleAddNewMember = (e: any) => {
		e.preventDefault()
		const randomId = v4()
		setAllMembers([
			...allMembers,
			{
				identifier: randomId,
				name: "",
				contactPerson: "",
				email: "",
				telNumber: "",
				address: "",
				postcode: "",
				postalDistrict: "",
				countryCode: "FI",
				languageCode: "fi",
				meetingInviteType: 1,
				invoiceSendingType: 1,
				businessId: "",
				eInvoiceOperator: "",
				eInvoiceAddress: ""
			}
		])
	}

	return (
		<Box sx={{ overflow: "hidden", height: "100%" }}>
			<Backdrop open={loading} sx={{ zIndex: 1000000 }}>
				<CircularProgress></CircularProgress>
				<Typography sx={{ color: "white" }}> Ladataan tietoja...</Typography>
			</Backdrop>

			<Stack
				direction="row"
				spacing={5}
				// sx={{position: 'absolute'}}
			>
				<Fab sx={{ borderRadius: "20px" }} color="secondary" variant="extended" href={process.env.PUBLIC_URL + "/files/Osakkaat_tuontipohja.xlsx"}>
					Lataa tuontikaavio <FileDownloadIcon sx={{ paddingLeft: "10px" }} />
				</Fab>
				<Fab sx={{ borderRadius: "20px" }} color="primary" variant="extended" component="label">
					Tuo tuontikaaviolla
					<input style={{ display: "none" }} onChange={handleReadFile} type="file" id="membersFile" />
					<GroupAddIcon sx={{ paddingLeft: "10px" }} />
				</Fab>
				<Fab sx={{ borderRadius: "20px" }} color="primary" variant="extended" onClick={handleAddNewMember}>
					Lisää osakas <PersonAddAlt1Icon sx={{ paddingLeft: "10px" }} />
				</Fab>
				<PopUpForIntroductionSupport
					pointerDirection="top"
					subclass="ob-after-properties"
					top={220}
					left={-19}
					title={"Pyydä apua Tievahdin käyttöönottoon."}
					popUpText={`Mikäli tarvitset apua Tievahdin käyttöönotossa, autamme mielellämme. Lisäämme puolestanne tiekunnan tien, kiinteistöt, osakkaat sekä yksiköt
					olemassa olevien tietojenne pohjalta. Palvelun hinta on <b>250 € (alv 0 %)</b>.`}
				></PopUpForIntroductionSupport>
			</Stack>

			<Button sx={{ position: "absolute", bottom: "25px", right: "25px" }} color="primary" variant="contained" onClick={handleCreateNewMembers}>
				Tallenna tiedot
			</Button>

			<TableContainer component={Paper} sx={{ height: "calc(100% - 60px)" }}>
				<Typography sx={{ fontSize: "12px", fontStyle: "oblique", fontWeight: "100", marginTop: "5px" }}>
					Pakolliset kentät on merkitty *-merkillä
				</Typography>
				<Table stickyHeader size="small" sx={{ overflow: "scroll" }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: "3px" }}></TableCell>
							<TableCell sx={{ width: "3px" }}></TableCell>
							<TableCell sx={{ minWidth: "160px" }}>
								<Typography>Nimi*</Typography>
							</TableCell>
							<TableCell sx={{ minWidth: "160px" }}>
								<Typography>Yhteyshenkilö</Typography>
							</TableCell>
							<TableCell sx={{ minWidth: "160px" }}>
								<Typography>Sähköposti</Typography>
							</TableCell>
							<TableCell>
								<Typography>Puhelinnumero</Typography>
							</TableCell>
							<TableCell sx={{ minWidth: "160px" }}>
								<Typography>Postiosoite*</Typography>
							</TableCell>
							<TableCell sx={{ minWidth: "60px" }}>
								<Typography>Postinro*</Typography>
							</TableCell>
							<TableCell>
								<Typography>Postitoimipaikka*</Typography>
							</TableCell>
							<TableCell sx={{ minWidth: "130px", paddingRight: "0px" }}>
								<Grid direction="row" container>
									<Typography sx={{ paddingTop: "7px" }}>Y-tunnus*</Typography>
									<Box component="span">
										<IconLinkButton
											url="https://tietopalvelu.ytj.fi/"
											title={
												<Typography sx={{ fontSize: "0.7rem", marginX: "2px", color: "white", fontWeight: "light" }}>
													Y-tunnus voi olla ainoastaan 9 merkkiä pitkä. Voit tarkistaa Y-tunnuksen YTJ:n sivuilta klikkaamalla ikonia.
												</Typography>
											}
										/>
									</Box>
								</Grid>
							</TableCell>
							<TableCell>
								<Typography>Maakoodi</Typography>
							</TableCell>
							<TableCell>
								<Typography>Kieli</Typography>
							</TableCell>
							<TableCell>
								<Typography>Laskun toimitustapa</Typography>
							</TableCell>
							<TableCell>
								<Typography>Kokouskutsun toimitustapa</Typography>
							</TableCell>
							<TableCell>
								<Typography>Verkkolaskuosoite</Typography>
							</TableCell>
							<TableCell>
								<Typography>Verkkolaskuoperaattori</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{allMembers.map((member: Member, index: number) => {
							return (
								<React.Fragment key={member.identifier}>
									<tr className="bulk-row">
										<td className="bulk-cell first-cell">{index + 1}.</td>
										<MemberRow allCountries={allCountries} index={index} member={member} setAllMembers={setAllMembers} />
									</tr>
								</React.Fragment>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default BulkMemberImportTool
