import React, { useState, useEffect } from "react"
import { CircularProgress } from "@mui/material"

/**
 * EmailVerificationProps määrittelee komponentille välitettävät propsit:
 *  - verifyUserEmail: funktio, joka ottaa vastaan verificationId:n ja vahvistaa käyttäjän sähköpostin
 *  - verificationId: tunniste linkistä
 */
interface EmailVerificationProps {
	verifyUserEmail: (verificationId: string) => Promise<any>
	verificationId: string
}

/**
 * EmailVerification näyttää kolmesta eri tilasta yhden:
 *  - "loading": spinneri
 *  - "success": onnistunut vahvistus
 *  - "error": virheenkäsittely
 */
const EmailVerification = ({ verifyUserEmail, verificationId }: EmailVerificationProps) => {
	const [status, setStatus] = useState<"loading" | "success" | "error">("loading")

	useEffect(() => {
		const doVerify = async () => {
			try {
				const res = await verifyUserEmail(verificationId)
				if (res?.status < 300) {
					setStatus("success")
				} else {
					setStatus("error")
				}
			} catch {
				setStatus("error")
			}
		}
		doVerify()
	}, [verifyUserEmail, verificationId])

	if (status === "loading") {
		return (
			<div className="login-page">
				<div className="email-verification-container loading-state">
					<CircularProgress />
					<p className="email-verification-text">Vahvistetaan sähköpostia...</p>
				</div>
			</div>
		)
	}

	if (status === "success") {
		return (
			<div className="login-page">
				<div className="email-verification-container success-state">
					<h2 className="email-verification-title">Sähköposti vahvistettu onnistuneesti!</h2>
					<button className="email-verification-btn" onClick={() => (window.location.href = "/")}>
						Palaa etusivulle
					</button>
				</div>
			</div>
		)
	}

	return (
		<div className="login-page">
			<div className="email-verification-container error-state">
				<h2 className="email-verification-title">Virhe sähköpostin vahvistuksessa!</h2>
				<p className="email-verification-text">Yritä uudelleen tai ota yhteyttä ylläpitoon.</p>
				<button className="email-verification-btn" onClick={() => (window.location.href = "/")}>
					Palaa etusivulle
				</button>
			</div>
		</div>
	)
}

export default EmailVerification
