import React, { useState, useEffect } from "react"
import { loginUser, registerUser, changePassword, sendPasswordResetEmail, verifyUserEmail } from "../../network/dataServices/userDataService"

import LoginForm from "./LoginForm"
import RegisterForm from "./RegisterForm"
import ForgotPasswordDialog from "./ForgotPasswordDialog"
import ChangePasswordForm from "./ChangePasswordForm"
import EmailVerification from "./EmailVerification"

import "./LoginPage.css"

/**
 * LoginPage:
 * - tarkistaa onko URL:ssa salasananvaihto- tai sähköpostinvahvistusparametreja
 * - näyttää lomakkeet ja modaalit (kirjautuminen, rekisteröityminen, unohtunut salasana)
 * - jos salasananvaihto tai sähköpostinvahvistus on käynnissä, se renderöi niille dedikoidut komponentit
 */
const LoginPage = () => {
	const [openForgetPasswordDialog, setOpenForgetPasswordDialog] = useState(false)

	// Sähköpostin verifiointi
	const [verificationId, setVerificationId] = useState<string | null>(null)
	const [isVerifyingEmail, setIsVerifyingEmail] = useState(false)

	// Salasanan vaihto
	const [isChangingPassword, setIsChangingPassword] = useState(false)
	const [changePasswordId, setChangePasswordId] = useState<string | null>(null)

	// Tarkistetaan URL-parametrit vain kerran
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)

		const vId = urlParams.get("verificationId")
		if (vId) {
			setVerificationId(vId)
			setIsVerifyingEmail(true)
		}

		const cId = urlParams.get("changePasswordId")
		if (cId) {
			setChangePasswordId(cId)
			setIsChangingPassword(true)
		}
	}, [])

	// Näytetään ChangePasswordForm, jos on salasanan vaihdon linkillä
	if (isChangingPassword && changePasswordId) {
		return (
			<ChangePasswordForm
				changePasswordId={changePasswordId}
				changePassword={changePassword}
				onSuccess={() => {
					setIsChangingPassword(false)
					window.location.href = "/"
				}}
			/>
		)
	}

	// Näytetään EmailVerification, jos on sähköpostin vahvistuslinkillä
	if (isVerifyingEmail && verificationId) {
		return <EmailVerification verifyUserEmail={verifyUserEmail} verificationId={verificationId} />
	}

	// Muussa tapauksessa: Login + Register -lomakkeet
	return (
		<div className="login-page">
			<div className="login-page-logo"></div>
			<div className="login-page-title">
				<h1>Verkkopalvelu - Tiekunnille</h1>
			</div>

			<ForgotPasswordDialog
				open={openForgetPasswordDialog}
				onClose={() => setOpenForgetPasswordDialog(false)}
				sendPasswordResetEmail={sendPasswordResetEmail}
			/>

			<div className="forms-container">
				<LoginForm setOpenForgetPasswordDialog={setOpenForgetPasswordDialog} loginUser={loginUser} />

				<RegisterForm registerUser={registerUser} />
			</div>
		</div>
	)
}

export default LoginPage
