import axios from "axios"
import { toast } from "react-toastify"
import authToken from "../../utils/authToken"
import config from "../../utils/config"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import axiosClient from "../axiosClient/axiosClient"
import { clearWholeStorage } from "../../components/UnitizationTool/unitizationToolUtils/unitizationStorage"
import { removeAllMarkersFromStorage } from "../../components/UnitizationTool/MapFeatureSelectBox/RouteDistanceCalculator/markersStorage"

/**
 * BACKEND_ROOT_URL backendin url
 */
const baseURL = config.BACKEND_ROOT_URL

/**
 * logoutUser:
 * - Lähettää palvelimelle pyynnön uloskirjautumisesta ja poistaa paikalliset tokenit.
 * - Suorittaa lopuksi sivun uudelleenlatauksen (ikkunan ohjaaminen "/").
 */
const logoutUser = () => {
	const requestBody = {
		token: authToken.getToken()
	}

	axios
		.post(`${baseURL}/logout`, requestBody, {
			headers: {
				Authorization: "Bearer " + authToken.getToken()
			}
		})
		.catch((err) => {
			console.error(" --- Error in logout: ", err, " --- ")
		})
		.finally(() => {
			userProfileStorage({ type: "logout" })
			authToken.deleteToken()
			authToken.deleteRefreshToken()
			removeAllMarkersFromStorage()
			window.location.href = "/"
		})
}

/**
 * forceLogoutUser:
 * - Sama kuin logoutUser, mutta kutsuu palvelimen "force logout" -reittiä.
 * - Käytetään esim. tilanteissa, joissa halutaan pakottaa ulos kirjautuminen.
 */
const forceLogoutUser = () => {
	const requestBody = {
		token: authToken.getToken()
	}

	axios
		.post(`${baseURL}/logout/force`, requestBody, {
			headers: {
				Authorization: "Bearer " + authToken.getToken()
			}
		})
		.catch((err) => {
			console.error(" --- Error in logout: ", err, " --- ")
		})
		.finally(() => {
			userProfileStorage({ type: "logout" })
			authToken.deleteToken()
			authToken.deleteRefreshToken()
			removeAllMarkersFromStorage()
			window.location.href = "/"
		})
}

/**
 * getUserRoadCooperatives:
 * - Hakee käyttäjän tiekunnat palvelimelta.
 * - Palauttaa axiosClientin avulla haetun responsen tai null, jos virhe.
 */
const getUserRoadCooperatives = async () => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/user/allRoadCooperatives"
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * getUserNotes:
 * - Hakee käyttäjän tallentamia muistiinpanoja (notes).
 * - Palauttaa axiosClient-responsen tai null virheessä.
 */
const getUserNotes = async () => {
	try {
		const res = await axiosClient({
			method: "get",
			url: "/api/user/allNotes"
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * createUserNote:
 * - Luo uuden muistiinpanon palvelimelle.
 * - Palauttaa responsen tai null virheessä.
 */
const createUserNote = async (note: Note) => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/user/createNote",
			data: note
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * updateNote:
 * - Päivittää olemassaolevan muistiinpanon.
 * - Jos note.id puuttuu, heitetään virhe.
 * - Palauttaa responsen tai null.
 */
const updateNote = async (note: Note) => {
	try {
		if (note.id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/updateNote",
			data: note
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * deleteNote:
 * - Poistaa muistiinpanon annetulla id:llä.
 * - Jos id puuttuu, heitetään virhe.
 * - Palauttaa responsen tai null.
 */
const deleteNote = async (id: string) => {
	try {
		if (id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/deleteNote",
			data: id
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * selectRoadCooperative:
 * - Valitsee aktiiviseksi tietyn tiekunnan (id & name).
 * - Päivittää tokenin, tallentaa valitun tiekunnan
 *   userProfileStorageen, tyhjentää yksiköintidatan ja
 *   lopuksi reloadaa sivun.
 */
const selectRoadCooperative = async (name?: string, id?: string) => {
	try {
		if (!id || !name) throw Error("invalid values")

		const res = await axiosClient({
			method: "post",
			url: "/api/user/selectRoadCooperative",
			data: { id }
		})

		await userProfileStorage({
			type: "setActiveRoadCooperative",
			payload: { roadCooperative: name, role: "Puheenjohtaja", id, permission: res.data.permission }
		})

		authToken.setToken(res.data.token)

		// Tyhjennetään yksiköintiin liittyvä localStorage data, koska tiekunta vaihtuu
		clearWholeStorage()

		// Poistetaan prompt-lippu sessiosta, jotta se voidaan näyttää uudestaan
		sessionStorage.removeItem("accountRenewPrompted")

		window.location.reload()
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * customUserBillingInfo laajentaa UserBillingInformationType-tyyppiä
 * mm. isMember-kentällä.
 */
interface customUserBillingInfo extends UserBillingInformationType {
	roadCooperativeName: string
	isMember?: boolean
}

/**
 * getAllUserBillingInformations:
 * - Hakee käyttäjän laskutustietoja, jos permission ei ole "municipality".
 * - Palauttaa listan customUserBillingInfo-olioita tai null virheessä.
 */
const getAllUserBillingInformations = async () => {
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission
	try {
		if (permission !== "municipality") {
			const res = await axiosClient({
				method: "GET",
				url: "/api/user/allUserMemberAndBillingInfos"
			})
			const data = await res.data

			const membersInfos: customUserBillingInfo[] = []
			for (let i = 0; i < data.length; i++) {
				const newInfoObj: customUserBillingInfo = {
					billingAddress: data[i].billing_address,
					businessId: data[i].business_id,
					contactPerson: data[i].contact_person,
					countryCode: data[i].country_code,
					eInvoiceAddress: data[i].e_invoice_address,
					eInvoiceOperator: data[i].e_invoice_operator,
					email: data[i].email,
					id: data[i].id,
					invoiceSendingType: data[i].invoice_sending_type,
					languageCode: data[i].language_code,
					meetingInviteType: data[i].meeting_invite_type,
					name: data[i].name,
					postcode: data[i].postcode,
					phoneNumber: data[i].phone_number,
					postalDistrict: data[i].postal_district,
					roadCooperativeName: data[i].road_cooperative_name,
					isMember: data[i].type == "member"
				}
				membersInfos.push(newInfoObj)
			}
			return membersInfos
		} else {
			return null
		}
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * registerUser:
 * - Rekisteröi uuden käyttäjän palvelimelle.
 * - Palauttaa { status: 200, res } onnistuessa tai { status: 400, error } virheessä.
 * - Jos backend antaa status 2xx, katsotaan onnistuneeksi.
 */
const registerUser = async (requestBody: any) => {
	try {
		const res = await axios({
			url: `${baseURL}/register`,
			method: "post",
			data: requestBody
		})
		if (res.status < 300) {
			// Onnistunut
			return { status: 200, res }
		} else {
			// Pakotettu virhe, jos status >= 300
			throw { status: 400, res }
		}
	} catch (error) {
		console.error(error)
		return { status: 400, error }
	}
}

/**
 * verifyUserEmail:
 * - Sähköpostin vahvistus annetulla verificationId:llä.
 * - Palauttaa axios-responsen tai { status: 400 }, jos virhe.
 */
const verifyUserEmail = async (verificationId: string) => {
	try {
		const body = { verificationId }
		const res = await axios({
			url: `${baseURL}/verifyEmail`,
			method: "post",
			data: body
		})
		if (res.status < 300) {
			return res
		} else {
			throw 400
		}
	} catch (err) {
		return { status: 400 }
	}
}

/**
 * changePassword:
 * - Vaihtaa salasanan annetulla changePasswordId:llä ja newPassword:llä.
 * - Käyttää Bearer-tokenia, jos se on tallennettu localStorageen.
 * - Onnistumisessa palauttaa responsen, virheessä näyttää toast-errorin ja/tai heittää poikkeuksen.
 */
const changePassword = async (changePasswordId: string, newPassword: string) => {
	try {
		const body = {
			changePasswordId,
			password: newPassword
		}
		const changePasswordRes = await axios({
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`
			},
			url: baseURL + "/login/changePassword",
			method: "post",
			data: body
		})
		if (changePasswordRes.status < 300) {
			return changePasswordRes
		} else {
			throw 400
		}
	} catch (error: any) {
		if (error.message === "Network Error") {
			console.error(error)
			toast.error("Yhteyden muodostaminen palvelimelle epäonnistui. Yritä myöhemmin uudelleen.", { role: "global" })
		} else {
			toast.error("Virhe salasanan vaihtamisessa. Yritä myöhemmin uudelleen.")
		}
	}
}

/**
 * Lähettää unohtuneen salasanan resetointilinkin käyttäjän sähköpostiin.
 * @param requestBody Objekti, jossa { loginId: string } -kenttä
 */
const sendPasswordResetEmail = async (requestBody: { loginId: string }) => {
	try {
		const res = await axios({
			url: baseURL + "/login/forgotPassword",
			method: "post",
			data: requestBody
		})
		if (res.status < 300) {
			return res
		} else {
			throw 400
		}
	} catch (err: any) {
		if (err.message === "Network Error") {
			console.error(err)
			toast.error("Yhteyden muodostaminen palvelimelle epäonnistui. Yritä myöhemmin uudelleen.", { role: "global" })
		} else {
			toast.error("Virhe salasanan resetointilinkin lähetyksessä. Tarkista sähköpostiosoite ja yritä uudelleen.")
		}
		throw err
	}
}

/**
 * loginUser:
 * - Yrittää kirjautua sisään annetulla (email, password).
 * - Tallentaa tokenin ja refreshTokenin, sekä käyttäjän perustiedot userProfileStorageen.
 * - Palauttaa onnistuneessa tapauksessa loginRes-objektin.
 * - Heittää erikseen Error("NETWORK_ERROR" | "USER_NOT_VERIFIED" | "WRONG_CREDENTIALS"),
 *   jotta UI-komponentti (LoginForm) voi näyttää toastin.
 */
const loginUser = async (email: string, password: string) => {
	try {
		const requestBody = { loginId: email, password }
		const loginRes = await axios({
			url: baseURL + "/login",
			method: "post",
			data: requestBody
		})

		// Tallennetaan tokenit localStorageen ja profile-sessio userProfileen
		authToken.setToken(loginRes.data.data.token)
		authToken.setRefreshToken(loginRes.data.data.refreshToken)

		userProfileStorage({
			type: "setUserProfile",
			payload: {
				name: loginRes.data.data.name,
				email: loginRes.data.data.email
			}
		})

		return loginRes
	} catch (error: any) {
		if (error.message === "Network Error") {
			throw new Error("NETWORK_ERROR")
		}
		if (error.response && error.response.status === 403) {
			throw new Error("USER_NOT_VERIFIED")
		}
		throw new Error("WRONG_CREDENTIALS")
	}
}

export {
	getUserRoadCooperatives,
	selectRoadCooperative,
	getAllUserBillingInformations,
	registerUser,
	loginUser,
	getUserNotes,
	createUserNote,
	updateNote,
	deleteNote,
	logoutUser,
	forceLogoutUser,
	verifyUserEmail,
	changePassword,
	sendPasswordResetEmail
}
