import axiosClient from "../axiosClient/axiosClient"

const createDumbData = async () => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/dumbData/createDumbData"
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

export { createDumbData }
