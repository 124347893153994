import React, { MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react"
import maplibregl, { LngLatBoundsLike, LngLatLike } from "maplibre-gl"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import "./Map.css"
import KipaLayersFilterBox from "./mapComponents/KipaLayersFilterBox"
import selectPropertiesFromMap from "./mapUtils/selectPropertiesFromMap"

import MapSearchBox from "./mapComponents/MapSearchBox"
import "react-toastify/dist/ReactToastify.css"
import mapClickFunction from "./mapUtils/getRoadByClick"
import loadKipaLayers from "./mapUtils/loadKipaLayers"
import getRoadLinkByClick from "./mapUtils/getRoadLinkByClick"
import searchPlaceByName from "./mapUtils/searchPlaceByName"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { costCenterLinksVar, costCenterPropertyVar } from "../../network/apolloClient/apolloClientMakeVars"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import selectedRoadLinksValues from "./mapUtils/selectedRoadLinksValues"
import waitUntilMapLoaded from "./mapUtils/waitUntilMapLoaded"

import * as turf from "@turf/turf"
import { Typography } from "@mui/material"
import { Paper } from "@mui/material"
import selectedCostCentersLinks from "./mapUtils/selectedCostCentersLinks"
import { Dialog } from "@mui/material"
import { Button } from "@mui/material"
import { DialogActions } from "@mui/material"
import { DialogContent } from "@mui/material"
import { TextField } from "@mui/material"
import showPropertyInfoByClickFunction from "./mapUtils/showPropertyInfoByClickFunction"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import CustomDialogActions from "../reusables/CustomDialogActions"

import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import config from "../../utils/config"

import { MapLayerType, mapProviderStateType, MapStateProvider, useMapState } from "../../utils/providers/mapProvider/mapProvider"
import { MapComponent } from "./MapComponent"
import { FeatureCollection, Position } from "geojson"
import { kipaMapDefaultLayers } from "./mapUtils/kipaDefaultLayers"
import CreativeCommosForMap from "../reusables/CreativeCommonsForMap"
import addSearchPropertyLayer from "./mapUtils/addSearchPropertyLayer"
import { Error } from "read-excel-file/types"
import { Alert } from "@mui/material"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import { Box } from "@mui/material"

import Markeri from "./Marker"
import { debounce } from "@mui/material"

const costCenterLinksQuery = gql`
	query getCostCenterLinks {
		costCenterLinksVar @client
	}
`

const costCenterPropertyQuery = gql`
	query getCostCenterProperty {
		costCenterPropertyVar @client
	}
`

const GET_COST_CENTERS_ALL_GEO_JSON_DATA = gql`
	query GetCostCentersAllData {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					name
					idColor
					id
					roadLinksByCostCenterId {
						nodes {
							linkId
							linkLength
							coordinates {
								lat
								lng
							}
							id
						}
					}
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							plotId
							propertyName
							id
							propertyTag
							establishmentsByPropertyId {
								nodes {
									roadUsageUnitByRoadUsageUnitId {
										roadUsageUnitType
										weight
										roadUsageUnitDescription
										iconImageName
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

// let containerElement: any = null
// let iconContainer: any = null

const mapMaxBounds: LngLatBoundsLike | undefined = [
	[11.5, 56.5], // Vasemman alakulman koordinaatit
	[38.5, 71] // Oikean yläkulman koordinaatit
]

interface MapComponentType {
	mapCenterPositionSetting?: {
		zoom: number
		centerPoint: { lat: number; lng: number }
	}
	costCenterData?: CostCenter
	showPropertyInfoByClick?: boolean
	selectedCostCenterId?: number // costCenterId
	mapFeatures?: {
		showPropertyInfoByClick?: boolean
		showProperties?: boolean
		showRoadLinks?: boolean
		editCostCenterData?: boolean
		createCostCenter?: boolean
		draw?: boolean
	}
	onDataChange?: () => void
	iconsFetched: boolean
	setIconsFetched: (i: boolean) => void
}

const MapContainer = ({
	mapCenterPositionSetting = { centerPoint: { lat: 26.645, lng: 64.101 }, zoom: 8 },
	costCenterData,
	selectedCostCenterId,
	mapFeatures,
	onDataChange,
	iconsFetched,
	setIconsFetched
}: MapComponentType) => {
	const [iconContainers, setIconContainers] = useState<any[]>([])
	const [containerElements, setContainerElements] = useState<any[]>([])

	const [initialDataLoaded, setInitialDataLoaded] = useState(false)
	const [mapFetchingError, setMapFetchingError] = useState(false)
	const [showMapIcons, setShowMapicons] = useState(false)
	const [geoJsonDatas, setGeoJsonData] = useState<any>()
	const [features, setFeatures] = useState<any[]>([])
	const [markers, setMarkers] = useState<any[]>([])
	const [arePropertyIdsLoaded, setArePropertyIdsLoaded] = useState(false)

	const mapContainerRef: any = useRef(null)
	const map: MapInstance = useRef(null)
	const [selectedRoadUsageUnitIds, setselectedRoadUsageUnitIds] = useState<{ id: number; name: string }[]>([])

	const [iconState, setIconState] = useState({
		size: 70, // Nykyinen koko
		previousSize: 70, // Edellinen koko
		opacity: 1, // Nykyinen läpinäkyvyys
		previousOpacity: 1, // Edellinen läpinäkyvyys
		gapBetweenIcons: 10, // Nykyinen väli
		previousGap: 10 // Edellinen väli
	})

	const [getAllGeoJson] = useLazyQuery(GET_COST_CENTERS_ALL_GEO_JSON_DATA)

	const onDataChangeFunc = async () => {
		// refetchRCGeoJson()
		if (!map.current) return
		if (onDataChange) onDataChange()
	}

	const initialProviderState: mapProviderStateType | undefined = {
		costCenterData: costCenterData ?? undefined,
		eventListeners: [],
		allProperties: [],
		mapCenterPositionSetting: mapCenterPositionSetting,
		mapSources: [],
		selectedCostCenterId: selectedCostCenterId,
		mapLayers: kipaMapDefaultLayers,
		mapFeatures: {
			createCostCenter: mapFeatures?.createCostCenter ?? false,
			editCostCenterData: mapFeatures?.editCostCenterData ?? false,
			showProperties: mapFeatures?.showProperties ?? false,
			showPropertyInfoByClick: mapFeatures?.showPropertyInfoByClick ?? false,
			showRoadLinks: mapFeatures?.showRoadLinks ?? false,
			draw: mapFeatures?.draw ?? false
		}
	}
	const currentMarkersRef = useRef<Record<string, maplibregl.Marker>>({}) // Säilytetään markerit viittauksena

	const fetchIcons = async (RcGeoJsonData: any, selectedCostCenterId: any, features: any, setMarkers: React.Dispatch<React.SetStateAction<any[]>>) => {
		const newMarkers: any[] = []

		RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.forEach((cc: any) => {
			if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
				cc.propertiesByCostCenterId?.nodes.forEach((property: any) => {
					const layerId = "KiinteistotunnuksenSijaintitiedot"
					if (features.length > 0) {
						const feature = features[0].find((f: any) => {
							// console.log(f, f.id, property.plotId)
							return f.id === property.plotId
						})

						if (feature) {
							const coordinates = feature.geometry.coordinates
							const markerKey = `${property.plotId}`

							const establishments = property.establishmentsByPropertyId.nodes

							// Kerää markerin tiedot
							newMarkers.push({
								key: markerKey,
								coordinates,
								establishments
							})
						}
					}
				})
			}
		})

		// Päivitä markerit Reactin tilaan
		setMarkers(newMarkers)
	}

	const handleZoomToIcons = (zoomLevel: number) => {
		setIconState((prev: any) => {
			const newState = { ...prev }

			// Zoomausasteikon asetukset
			const baseZoom = 16 // Lähtökohta zoomille
			const baseSize = 80 // Peruskoko pikseleinä
			const minSize = 80 // Minimiko koko
			const maxSize = 160 // Maksimikoko
			const scaleFactor = Math.pow(1.3, zoomLevel - baseZoom) // Zoomin suhteellinen skaalauskerroin

			// Lasketaan uusi koko skaalauskertoimella, mutta pidetään se rajoissa
			newState.size = Math.min(Math.max(baseSize * scaleFactor, minSize), maxSize)

			// Lasketaan ikonien välinen etäisyys suhteessa ikonien kokoon
			newState.gapBetweenIcons = newState.size * 0.4 // Etäisyys on 60 % ikonien koosta

			// Päivitetään läpinäkyvyys
			if (zoomLevel < 16 || zoomLevel > 25) {
				newState.opacity = 0 // Piilotetaan ikonit liian pienellä tai suurella zoomilla
			} else {
				newState.opacity = 1 // Näytetään ikonit muuten
			}

			// console.log("Päivitetty iconState:", newState)
			return {
				...prev,
				previousSize: prev.size, // Tallenna vanha koko
				size: newState.size,
				previousGap: prev.gapBetweenIcons, // Tallenna vanha väli
				gapBetweenIcons: newState.gapBetweenIcons,
				previousOpacity: prev.opacity, // Tallenna vanha opacity
				opacity: newState.opacity
			}
		})
	}

	useEffect(() => {
		// MAP INITIAL LOADING EFFECT
		const func = async () => {
			const { data: geoJsonData } = await getAllGeoJson()
			setGeoJsonData(geoJsonData)
			map.current = new maplibregl.Map({
				container: mapContainerRef.current ?? "map",
				style: `https://avoin-karttakuva.maanmittauslaitos.fi/vectortiles/stylejson/v20/backgroundmap.json?TileMatrixSet=WGS84_Pseudo-Mercator&api-key=${config.MML_API_KEY}`,
				center: [mapCenterPositionSetting.centerPoint.lat, mapCenterPositionSetting.centerPoint.lng],
				zoom: mapCenterPositionSetting.zoom,
				maxBounds: mapMaxBounds
			})
			let previousZoomLevel: number = Math.floor(map.current.getZoom())
			map.current.on("error", (error: Error) => {
				if (!String(error.error).includes("does not exist in the map's style and cannot be styled")) {
					console.error("Virhe kartan latauksessa:", error.error)
					setMapFetchingError(true)
				}
			})

			map.current.doubleClickZoom.disable()
			map.current.on("load", () => {
				addSearchPropertyLayer(map)
				loadKipaLayers(map) // loading MML kiinteistöpalvelu layer
				setMapFetchingError(false)

				map.current.setLayoutProperty("KiinteistotunnuksenSijaintitiedot", "visibility", "visible")
				setArePropertyIdsLoaded(true)
				map.current.setLayoutProperty("KiinteistorajanSijaintitiedot", "visibility", "visible")
				map.current.setLayoutProperty("PalstanSijaintitiedot", "visibility", "visible")
				if (geoJsonData?.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length > 0) {
					addAllCostCentersRoadLinksSourceLayer(map, geoJsonData, mapFeatures?.showRoadLinks, selectedCostCenterId),
						Promise.all([showAllRoadCooperativeProperties(map, geoJsonData, mapFeatures?.showProperties, selectedCostCenterId, showMapIcons)])

							.then((data) => {
								// Kun molemmat funktiot ovat valmiita, kutsutaan fetchIcons
								// console.log("DAATA", data)
								// setFeatures(data)
							})
							.catch((error) => {
								console.error("Error loading data or fetching icons:", error)
							})
				}
			})

			const onZoomEnd = () => {
				const currentZoomLevel = Math.floor(map.current!.getZoom())
				if (currentZoomLevel !== previousZoomLevel) {
					// console.log(currentZoomLevel, previousZoomLevel)
					previousZoomLevel = currentZoomLevel
					handleZoomToIcons(currentZoomLevel)
				}
			}

			map.current.on("zoomend", onZoomEnd)

			return () => {
				if (map.current) {
					map.current.off("zoomend", onZoomEnd)
				}
			}

			setInitialDataLoaded(true)
		}
		func()
		// clean up on unmount
		return () => map.current.remove()
	}, [])

	useEffect(() => {
		if (!map.current || !geoJsonDatas || !features) return
		if (features[0] && showMapIcons) {
			fetchIcons(geoJsonDatas, selectedCostCenterId, features, setMarkers)
		}
	}, [iconState, features, geoJsonDatas, showMapIcons])

	window.addEventListener("mousedown", function (e) {
		// Funktio estää käyttäjää painamasta hiiren keskimmäistä painiketta.
		// Tarkistetaan, onko painettu nappi keskinäppäin (2)
		if (e.which === 2) {
			e.preventDefault() // Estetään oletustoiminto
		}
	})

	const showAllRoadCooperativeProperties = async (
		map: MapInstance,
		RcGeoJsonData: RoadCooperative,
		showProperties?: boolean,
		selectedCostCenterId?: number,
		showMapIcons?: boolean
	) => {
		return new Promise((resolve, reject) => {
			if (!showProperties || !RcGeoJsonData) return

			// Poista feature state ennen kuin lähdet käsittelemään
			map.current.removeFeatureState({
				source: "kipa",
				sourceLayer: "PalstanSijaintitiedot"
			})

			// Säilytä elementit taulukoissa ennen renderöintiä

			// Iteroi kaikki roadCooperative ja niiden costCenterit
			RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.forEach((cc: any) => {
				if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
					cc.propertiesByCostCenterId?.nodes.forEach((property: any) => {
						map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: property.plotId }, { hover: true })

						// "sourcedata" -tapahtuma, käsitellään heti
					})
				}
			})
			const handleMoveEnd = debounce(() => {
				const featuress = map.current.querySourceFeatures("kipa", {
					sourceLayer: "KiinteistotunnuksenSijaintitiedot"
				})

				setFeatures([featuress])
				resolve(featuress)
			}, 300)

			map.current.on("sourcedata", (e: any) => {
				// Varmista, että haluat tarkastella oikeaa kerrosta

				if (e.isSourceLoaded && e.sourceId === "kipa" && map.current.getLayer("KiinteistotunnuksenSijaintitiedot") && !iconsFetched && showMapIcons) {
					setIconsFetched(true)
					//
					const featuress = map.current.querySourceFeatures("kipa", {
						sourceLayer: "KiinteistotunnuksenSijaintitiedot"
					})

					// const prop = featuress.find((feature: any) => {
					// 	console.log(feature.properties)
					// 	return feature.properties.id == "66860503"
					// })
					// console.log("FEATURES", prop)

					// console.log(features)

					if (featuress && featuress.length > 0) {
						setFeatures([featuress])
						resolve(featuress) // Palauta ominaisuudet, kun niitä löytyy
					}
				}
			})

			map.current.on("moveend", handleMoveEnd)

			// const sourceId = "kipa"

			// // Tarkista, onko lähde ladattu
			// if (!map.current.getSource(sourceId)) {
		})
	}

	return (
		<MapStateProvider initialState={initialProviderState}>
			<div
				className="map-container"
				id="map-container"
				onAuxClick={(e) => {
					e.preventDefault()
				}}
				onClick={void 0}
				style={{ overflow: "hidden" }}
			>
				<MapComponent
					onDataChange={onDataChangeFunc}
					map={map}
					selectedCostCenterId={selectedCostCenterId}
					showMapIcons={showMapIcons}
					setShowMapIcons={setShowMapicons}
					selectedRoadUsageUnitIds={selectedRoadUsageUnitIds}
					setselectedRoadUsageUnitIds={setselectedRoadUsageUnitIds}
				/>
				<div className="map" ref={mapContainerRef} id="map" />
				<CreativeCommosForMap />
				{mapFetchingError ? (
					<Box
						sx={{
							position: "absolute", // Tai 'relative', 'fixed', tarpeen mukaan
							top: 5,
							left: 350,
							zIndex: 9999 // Nostaa elementin muiden päälle
						}}
					>
						<Alert
							icon={<ReportProblemIcon fontSize="inherit" />}
							severity="error"
							sx={{
								width: "100%",
								fontSize: "18px", // Isompi fonttikoko
								fontWeight: "500" // Paksumpi fontti
							}}
						>
							Maanmittauslaitoksen karttapalvelussa on häiriö!
						</Alert>
					</Box>
				) : null}

				{showMapIcons && arePropertyIdsLoaded ? (
					<div style={{ width: "100%", height: "100vh" }} ref={mapContainerRef}>
						{markers.map((marker) => (
							<Markeri
								maps={map.current}
								key={marker.key}
								coordinates={marker.coordinates}
								establishments={marker.establishments}
								iconState={iconState}
								selectedRoadUsageUnits={selectedRoadUsageUnitIds}
							/>
						))}
					</div>
				) : null}
			</div>
		</MapStateProvider>
	)
}

const addAllCostCentersRoadLinksSourceLayer = (map: any, RCGeoJson: RoadCooperative, showRoadLinks: boolean | undefined, selectedCostCenterId?: number) => {
	if (RCGeoJson === undefined) return

	const allCostCenters = []
	let bounds = null
	if (selectedCostCenterId === undefined) {
		const cc = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[0]
		if (cc.roadLinksByCostCenterId.nodes.length > 0) {
			bounds = new maplibregl.LngLatBounds(
				{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat },
				{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat }
			)
		}
	}
	for (let i = 0, len = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
		const cc = RCGeoJson.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]
		if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
			const costCenter = {
				...cc,
				allCoordinatePoints: []
			}
			for (let i2 = 0, len = cc.roadLinksByCostCenterId?.nodes.length; len > i2; i2++) {
				const link = cc.roadLinksByCostCenterId?.nodes[i2]
				const linkCoordinateArray = []
				for (let i3 = 0, len = link.coordinates.length; len > i3; i3++) {
					const coordinates = link.coordinates[i3]
					bounds?.extend([coordinates.lat, coordinates.lng])
					linkCoordinateArray.push([coordinates.lat, coordinates.lng])
				}
				costCenter.allCoordinatePoints.push(linkCoordinateArray)
			}
			allCostCenters.push(costCenter)
		}
	}

	for (let i = 0, len = allCostCenters.length; len > i; i++) {
		const costCenter = allCostCenters[i]

		const geoJsonData: FeatureCollection = {
			type: "FeatureCollection",
			features: [
				{
					properties: {},
					type: "Feature",
					geometry: {
						type: "MultiLineString",
						coordinates: costCenter.allCoordinatePoints
					}
				}
			]
		}

		map.current.addSource(`costCenterRoadLinks${costCenter.name + i}`, {
			type: "geojson",
			data: geoJsonData
		})

		map.current.addLayer({
			id: `costCenterRoadLinks${costCenter.name + i}`,
			type: "line",
			source: `costCenterRoadLinks${costCenter.name + i}`,
			layout: {
				"line-join": "round",
				"line-cap": "round",
				visibility: showRoadLinks ? "visible" : "none"
			},
			paint: {
				"line-color": costCenter.idColor ?? "#505050",
				"line-width": 5
			}
		})
	}
	if (bounds) {
		map.current.fitBounds(bounds, {
			padding: 50
		})
	}
}

export { MapContainer }
