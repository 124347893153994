import { Box } from "@mui/material"
import { TextField } from "@mui/material"
import { MenuItem } from "@mui/material"
import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import { getAllCountries } from "../../network/dataServices/generalDataService"

const OrganisationUserInfo = ({ allCountries, roadCooperative, setRoadCooperative }: any) => {
	const [userProfile, setUserProfile] = useState({ name: "", email: "" })
	const [alert, setAlert] = useState("")

	useEffect(() => {
		const user = userProfileStorage({ type: "getUserProfile" })
		console.log(user)
		setUserProfile(user)
	}, [])

	return (
		<Grid item container xs={12} columnSpacing={2} rowSpacing={1} sx={{ justifyContent: "center" }}>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Yrityksen nimi"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, name: e.target.value })}
					value={roadCooperative.name}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Y-tunnus"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, businessId: e.target.value })}
					value={roadCooperative.businessId}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Puhelinnumero"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, telNumber: e.target.value })}
					value={roadCooperative.telNumber}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postiosoite"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, address: e.target.value })}
					value={roadCooperative.address}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postinumero"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, postalCode: e.target.value })}
					value={roadCooperative.postalCode}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postitoimipaikka"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, postalDistrict: e.target.value })}
					value={roadCooperative.postalDistrict}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Yhteyshenkilö"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, contactPerson: e.target.value })}
					value={roadCooperative.contactPerson}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Maa"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, countryCode: e.target.value })}
					value={roadCooperative.countryCode}
					variant="outlined"
					select
					fullWidth
					required
				>
					{allCountries
						.sort((a: Country, b: Country) => a.country.localeCompare(b.country, "fi"))
						.map((country: Country) => {
							return (
								<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
									{country.country} - {country.countryCode}
								</MenuItem>
							)
						})}
				</TextField>
			</Grid>
			{/* <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
				<TextField
					label="Kieli"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, languageCode: e.target.value })}
					value={roadCooperative.languageCode}
					variant="outlined"
					select
					fullWidth
					required
				>
					<MenuItem value={"fi"}>Suomi</MenuItem>
					<MenuItem value={"sv"}>Ruotsi</MenuItem>
					<MenuItem value={"en"}>Englanti</MenuItem>
				</TextField>
			</Grid> */}
		</Grid>
	)
}

export default OrganisationUserInfo
