import React, { useEffect, useState } from "react"
import "./SelectRoadCooperative.css" // tuo uusi tiedosto
import { getUserRoadCooperatives, logoutUser, selectRoadCooperative } from "../../network/dataServices/userDataService"
import { Tooltip, Stack, TextField, Paper, Typography, Button, Box, CircularProgress, Fab } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import LogoutIcon from "@mui/icons-material/Logout"
import { toast } from "react-toastify"
import { createDumbData } from "../../network/dataServices/dumbDataService"
import CreateRoadCooperative from "./CreateRoadCooperative"

type RoadCooperativeListItem = { name: string; id: string }

const SelectRoadCooperative = () => {
	const [allRoadCooperatives, setAllRoadCooperatives] = useState<{ name: string; id: string; type: string }[]>([])
	const [roadCooperativesLoaded, setRoadCooperativesLoaded] = useState<boolean>(false)
	const [roadCooperativeList, setRoadCooperativeList] = useState<RoadCooperativeListItem[]>([])
	const [creatingNewRC, setCreatingNewRC] = useState<boolean>(false)

	const handleSelectRoadCooperative = async (name: string, id: string) => {
		selectRoadCooperative(name, id)
	}

	const handleCreateDumpData = async () => {
		console.log("Uusi dumppidata")
		const addRes = await createDumbData()
		if (addRes != null) {
			toast.success("Dumpdatan lisätty onnistuneesti")
		} else {
			toast.error("Dumpdatan lisääminen epäonnistui!")
		}
		window.location.reload()
	}

	const handleFilterChange = (value: string) => {
		if (!value) setRoadCooperativeList(allRoadCooperatives)
		else {
			const temp = allRoadCooperatives.filter((rc) => rc.name.toUpperCase().includes(value.toUpperCase()))
			setRoadCooperativeList(temp)
		}
	}

	useEffect(() => {
		getUserRoadCooperatives().then((data) => {
			if (data) {
				const sorted = data.data.sort((a: any, b: any) => a.name.localeCompare(b.name))
				setAllRoadCooperatives(sorted)
				setRoadCooperativeList(sorted)
			}
			setRoadCooperativesLoaded(true)
		})
	}, [])

	if (!roadCooperativesLoaded) {
		return (
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		)
	}

	if ((allRoadCooperatives.length === 0 && process.env.NODE_ENV !== "development") || creatingNewRC) {
		return (
			<div className="selectRoadCooperative-page">
				<Box sx={{ width: "100%", paddingTop: "10vh", alignItems: "center" }}>
					<CreateRoadCooperative creatingNew={creatingNewRC} setCreatingNew={setCreatingNewRC} />
				</Box>
			</div>
		)
	}

	return (
		<div className="selectRoadCooperative-page">
			<Box sx={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: "10vh", alignItems: "center" }}>
				<Tooltip title="Kirjaudu ulos">
					<Fab sx={{ position: "fixed", top: "10px", right: "15px", zIndex: 2000 }} color="primary" size="small" onClick={logoutUser}>
						<LogoutIcon />
					</Fab>
				</Tooltip>
				<Paper
					sx={{
						height: "400px",
						padding: "20px",
						width: "min(90vw, 650px)",
						textAlign: "center",
						display: "flex",
						flexDirection: "column"
					}}
					elevation={10}
				>
					<Typography sx={{ fontSize: "2rem", height: "60px" }}>Valitse tiekunta</Typography>
					<Stack
						spacing={2}
						direction={"row"}
						sx={{ alignItems: "center", alignSelf: "center", height: "60px", width: "min(90vw, 500px)", marginBottom: "10px" }}
					>
						<SearchIcon />
						<TextField
							fullWidth
							label="Etsi tiekunta..."
							color="primary"
							sx={{ width: 460 }}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e.target.value)}
						/>
						<Tooltip title="Luo uusi tiekunta">
							<AddIcon fontSize="large" color="primary" onClick={() => setCreatingNewRC(true)} sx={{ cursor: "pointer" }} />
						</Tooltip>
					</Stack>
					{process.env.NODE_ENV === "development" && <Button onClick={handleCreateDumpData}>Luo dumppidata</Button>}
					<Box sx={{ width: "min(90vw, 500px)", maxHeight: "300px", overflow: "auto", alignSelf: "center" }}>
						{roadCooperativeList.length !== 0 ? (
							roadCooperativeList.map((rc) => (
								<Button
									key={rc.id}
									fullWidth
									sx={{ marginBottom: "4px", width: "calc(83%)" }}
									variant="contained"
									onClick={() => handleSelectRoadCooperative(rc.name, rc.id)}
								>
									{rc.name}
								</Button>
							))
						) : (
							<Typography>Ei tiekuntadataa</Typography>
						)}
					</Box>
				</Paper>
			</Box>
		</div>
	)
}

export default SelectRoadCooperative
