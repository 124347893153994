import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import maplibregl from "maplibre-gl"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import "./Map.css"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { Dialog } from "@mui/material"
import { DialogContent } from "@mui/material"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import CustomDialogActions from "../reusables/CustomDialogActions"

import CloseIcon from "@mui/icons-material/Close"

import { IconButton } from "@mui/material"
import MapPropertyInfoPopup from "./mapComponents/MapPropertyInfoPopoup"
import { useMapState } from "../../utils/providers/mapProvider/mapProvider"

import "./Map.css"
import showPropertyInfoByClickFunction from "./mapUtils/showPropertyInfoByClickFunction"
import { mapBoxDrawStyles } from "./mapUtils/mapboxDrawStyle"
import { addMapDrawAreaMarkers } from "./mapDrawControllers"
import PaintMode from "mapbox-gl-draw-paint-mode"
import { MapToolAndLayersPopup } from "./MapToolLayerPopup"
import { setCostCenterBoundsAndVisibility } from "./setCostCenterBoundsAndVisibility"
import { addMapCostCentersLayersToProvider } from "./addMapCostCentersLayersToProvider"
import PropertySearchBox from "./PropertySearchBox"

import ChatPopUp from "./ChatPopUp"
import { Grid } from "@mui/material"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"

const GET_PROPERTIES = gql`
	query GetProperties {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					propertiesByCostCenterId {
						nodes {
							id
							mmlPropertyId
							plotId
							propertyName
							propertyTag
							establishmentsByPropertyId {
								nodes {
									roadUnits
									roadUsageUnitId
									roadUsageUnitByRoadUsageUnitId {
										roadUsageUnitType
										weight
										roadUsageUnit
										id
									}
									additionalInformation
									amount
									discretionalyCorrectionMultiplier
									distanceCorrectionMultiplier
									lateralDirectionCorrectionMultiplier
									lateralDirection
									operatingDistance
									roadCooperativeMemberByRoadCooperativeMemberId {
										name
										id
										userEmail
									}
									weightCorrectionMultiplier
									id
								}
							}
							costCenterByCostCenterId {
								idColor
								name
								id
							}
							id
						}
						totalCount
					}
					idColor
					name
					id
				}
			}
		}
	}
`
const GET_COST_CENTERS_ALL_GEO_JSON_DATA = gql`
	query GetCostCentersAllData {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					name
					idColor
					id
					totalUnits
					roadLinksByCostCenterId {
						nodes {
							linkId
							linkLength
							coordinates {
								lat
								lng
							}
							id
						}
					}
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							plotId
							propertyName
							id
							propertyTag
						}
					}
				}
			}
		}
	}
`

interface MapComponentType {
	map: MapInstance
	selectedCostCenterId?: number
	onDataChange?: () => void
	showMapIcons: boolean
	setShowMapIcons: (showMapIcons: boolean) => void
	selectedRoadUsageUnitIds: RoadUsageUnits
	setselectedRoadUsageUnitIds: (RoadUsageUnits: RoadUsageUnits) => void
}

const MapComponent = ({
	map,
	selectedCostCenterId,
	onDataChange,
	showMapIcons,
	setShowMapIcons,
	selectedRoadUsageUnitIds,
	setselectedRoadUsageUnitIds
}: MapComponentType) => {
	const { state, dispatch } = useMapState()

	const [bounds, setBounds] = useState<maplibregl.LngLatBounds | null>()
	const [mapDrawAreaMarkersAdded, setMapDrawAreaMarkersAdded] = useState(false)
	const [allGeoJsonData, setAllGeoJsonData] = useState()
	const [roadUsageUnits, setRoadUsageUnits] = useState<RoadUsageUnits>()

	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission

	const propertyInfoClickFunctionRef = useRef(null)
	const roadLinkClickRef = useRef(null)
	const drawFunc: MutableRefObject<MapboxDraw | null> = useRef(
		new MapboxDraw({
			displayControlsDefault: false,
			controls: {
				polygon: true,
				trash: true,
				line_string: true,
				point: true
			},
			modes: Object.assign(
				{
					draw_paint_mode: PaintMode
				},
				MapboxDraw.modes
			),
			styles: mapBoxDrawStyles
		})
	)

	const [positionOffset, setPositionOffset] = useState(236)
	const [totalUnits, setTotalUnits] = useState(0)

	const { data: allPropertiesData } = useQuery(GET_PROPERTIES, {
		onCompleted(data) {
			const roadUsageUnitList: RoadUsageUnits = []

			for (let i = 0, len = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
				const cc = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]

				for (let i2 = 0, len = cc.propertiesByCostCenterId?.nodes.length; len > i2; i2++) {
					const property = cc.propertiesByCostCenterId?.nodes[i2]
					for (let i3 = 0, len = property.establishmentsByPropertyId?.nodes.length; len > i3; i3++) {
						const est = property.establishmentsByPropertyId?.nodes[i3]
						const newItem = {
							id: est.roadUsageUnitByRoadUsageUnitId?.id,
							name: est.roadUsageUnitByRoadUsageUnitId?.roadUsageUnitType
						}

						// Tarkistetaan, onko `id` jo listassa ennen lisäämistä
						if (!roadUsageUnitList.some((item) => item.id === newItem.id)) {
							roadUsageUnitList.push(newItem)
						}
					}
				}
			}

			setRoadUsageUnits(roadUsageUnitList.filter((unit: any) => unit.name !== "" && unit.name !== null).sort((a, b) => a.name.localeCompare(b.name)))

			let allProperties: Property[] = []
			for (let i = 0; data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length > i; i++) {
				allProperties = allProperties.concat(data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i].propertiesByCostCenterId.nodes)
			}
			dispatch({ type: "setAllProperties", payload: allProperties })
		}
	})

	const { data: allRCGeoData, loading } = useQuery(GET_COST_CENTERS_ALL_GEO_JSON_DATA, {
		onCompleted: (data) => {
			if (!data?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId) return
			if (
				data.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes == null ||
				data.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes.length == 0
			)
				return
			addMapCostCentersLayersToProvider(state, data, dispatch)
			setAllGeoJsonData(allGeoJsonData)
			const totalUnitsSum = data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.reduce(
				(sum: any, costCenter: { totalUnits: any }) => sum + costCenter.totalUnits,
				0
			)

			setTotalUnits(totalUnitsSum)
		}
	})

	useEffect(() => {
		// console.log(totalUnits)
		if (!loading && totalUnits > 0) {
			setPositionOffset(174)
		}
	}, [totalUnits, loading])

	const [getAllGeoJson] = useLazyQuery(GET_COST_CENTERS_ALL_GEO_JSON_DATA, {
		fetchPolicy: "network-only"
	})

	const [getAllProperties] = useLazyQuery(GET_PROPERTIES, {
		fetchPolicy: "network-only"
	})

	useEffect(() => {
		if (state.propertyDialogData?.dataChanged) {
			// Property data muuttuu ja se päivitetään tarvittaviin paikkoihin
			dispatch({ type: "setPropertyDialogData", payload: { ...state.propertyDialogData, dataChanged: false } })
			const func = async () => {
				const { data: geoJsonData } = await getAllGeoJson()
				reselectProperties(map, geoJsonData, state.activePropertyPlotId, selectedCostCenterId)
				const { data: propetyData } = await getAllProperties()
				showPropertyInfoByClickFunction(map, propetyData, dispatch, propertyInfoClickFunctionRef, false)
				if (state.currentPopup) {
					// Poistetaan kartalta vanha popup jossa on vanhentunutta dataa
					state.currentPopup.remove()
					dispatch({ type: "setCurrentPopup", payload: undefined })
				}
				if (state.activePropertyPlotId) {
					// Asetetaan valittu palsta ei valituksi
					// map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: state.activePropertyPlotId }, { selected: false })
					// dispatch({ type: "setActivePropertyPlotId", payload: undefined })
				}
			}
			func()
		}
	}, [state.propertyDialogData?.dataChanged])

	useEffect(() => {
		// Tähän effectiin kaikki kartan initial funktiot
		if (!map.current) return
		const func = async () => {
			const { data: geoJsonData } = await getAllGeoJson()
			reselectProperties(map, geoJsonData, undefined, selectedCostCenterId)
			const { data: propetyData } = await getAllProperties()
			// showPropertyInfoByClickFunction(map, propetyData, dispatch, propertyInfoClickFunctionRef, false)
		}
		// func()

		if (state.mapFeatures.draw) {
			// map.current.addControl(drawFunc.current)
			toggleDrawControllers("on")
		}
		// if (state.mapFeatures.editCostCenterData && allRCGeoData) {
		// 	editCostCenter(map, allRCGeoData, roadLinkClickRef, state, dispatch, false)
		// }
		if (state.mapFeatures.showPropertyInfoByClick && allPropertiesData) {
			showPropertyInfoByClickFunction(map, allPropertiesData, dispatch, propertyInfoClickFunctionRef, false)
		}
	}, [map.current, allPropertiesData, allRCGeoData])

	useEffect(() => {
		if (!map.current?._loaded) return
		setCostCenterBoundsAndVisibility(map, selectedCostCenterId, allRCGeoData, setBounds, state, dispatch)
		showSelectedCostCenterProperties(map, allRCGeoData, state.mapFeatures?.showProperties, selectedCostCenterId)
	}, [selectedCostCenterId])

	useEffect(() => {
		if (bounds) {
			map.current.fitBounds(bounds, {
				padding: 50
			})
		}
	}, [bounds])

	const toggleDrawControllers = (toggleType: "on" | "off") => {
		if (!mapDrawAreaMarkersAdded && toggleType === "on") {
			addMapDrawAreaMarkers(map, "on", mapDrawAreaMarkersAdded)
			setMapDrawAreaMarkersAdded(true)
		}
		if (mapDrawAreaMarkersAdded && toggleType === "off") {
			addMapDrawAreaMarkers(map, "off", mapDrawAreaMarkersAdded)
		}
	}

	const handleRoadUsageUnitSelect = (selected: { id: number; name: string }[]) => {
		setselectedRoadUsageUnitIds(selected)
	}

	useEffect(() => {
		if (!map.current) return
		if (state.mapFeatures.draw) {
			map.current.addControl(drawFunc.current)
			toggleDrawControllers("on")
		} else {
			map.current.removeControl(drawFunc.current)
			toggleDrawControllers("off")
		}
	}, [state.mapFeatures.draw])

	useEffect(() => {
		if (!map.current) return
		if (state.mapFeatures.showPropertyInfoByClick && propertyInfoClickFunctionRef.current === null) {
			showPropertyInfoByClickFunction(map, allPropertiesData, dispatch, propertyInfoClickFunctionRef, false)
		} else {
			showPropertyInfoByClickFunction(map, allPropertiesData, dispatch, propertyInfoClickFunctionRef, true)
			propertyInfoClickFunctionRef.current = null
		}
	}, [state.mapFeatures.showPropertyInfoByClick])

	useEffect(() => {
		if (!map.current) return

		showPropertiesByRoadUsageUnit(map, allPropertiesData, selectedCostCenterId, selectedRoadUsageUnitIds)
	}, [selectedRoadUsageUnitIds])

	return (
		<>
			<MapToolAndLayersPopup map={map} drawFunc={drawFunc} />

			<PropertyDialog />
			{roadUsageUnits && (
				<Grid sx={{ position: "absolute", left: 0, right: 0, top: positionOffset, zIndex: 10 }}>
					<PropertySearchBox
						map={map}
						roadUsageUnits={roadUsageUnits}
						handleRoadUsageUnitSelect={handleRoadUsageUnitSelect}
						showMapIcons={showMapIcons}
						setShowMapIcons={setShowMapIcons}
					/>
				</Grid>
			)}

			{/* <Paper sx={{ zIndex: 2000, position: "fixed", bottom: "15px", left: "170px" }}>
				<Dropdown
					options={roadUsageUnits}
					padding={"7px"}
					width="200px"
					top="0px"
					left="4px"
					onSelect={(selected: any) => {
						handleRoadUsageUnitSelect(selected.id)
					}}
					label="Liikennelajit"
					firstValue={"kaikki"}
				/>
			</Paper> */}

			{permission !== "municipality" && <ChatPopUp></ChatPopUp>}
		</>
	)
}

const showPropertiesByRoadUsageUnit = (
	map: MapInstance,
	allProperties: any,
	selectedCostCenterId?: number | undefined,
	selectedRoadUsageUnitIds?: { id: number; name: string }[] | null
) => {
	if (allProperties) {
		map.current.removeFeatureState({
			source: "kipa",
			sourceLayer: "PalstanSijaintitiedot"
		})
		// console.log(selectedRoadUsageUnitIds)

		for (let i = 0, len = allProperties.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
			const cc = allProperties.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]

			if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
				for (let i2 = 0, len = cc.propertiesByCostCenterId?.nodes.length; len > i2; i2++) {
					if (selectedRoadUsageUnitIds?.length && selectedRoadUsageUnitIds?.length > 0) {
						const matchingEstablishment = cc.propertiesByCostCenterId?.nodes[i2].establishmentsByPropertyId.nodes.find((est: Establishment) =>
							selectedRoadUsageUnitIds?.map((k: any) => String(k.id)).includes(String(est.roadUsageUnitId))
						)

						// Jos täsmäävä establishment löytyi, tulosta propertyId
						if (matchingEstablishment) {
							map.current.setFeatureState(
								{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: cc.propertiesByCostCenterId?.nodes[i2].plotId },
								{ hover: true }
							)
						}
					} else {
						map.current.setFeatureState(
							{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: cc.propertiesByCostCenterId?.nodes[i2].plotId },
							{ hover: true }
						)
					}
				}
			}
		}
	}
}

const reselectProperties = (map: MapInstance, RcGeoJsonData: RoadCooperative, activePlotId: string | undefined, selectedCostCenterId?: number | undefined) => {
	if (RcGeoJsonData) {
		map.current.removeFeatureState({
			source: "kipa",
			sourceLayer: "PalstanSijaintitiedot"
		})
		for (let i = 0, len = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
			const cc = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]
			if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
				for (let i2 = 0, len = cc.propertiesByCostCenterId?.nodes.length; len > i2; i2++) {
					map.current.setFeatureState(
						{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: cc.propertiesByCostCenterId?.nodes[i2].plotId },
						{ hover: true }
					)
				}
			}
		}
		if (activePlotId) {
			map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: activePlotId }, { selected: true })
		}
	}
}

const showSelectedCostCenterProperties = (map: MapInstance, RcGeoJsonData: any, showProperties?: boolean, selectedCostCenterId?: number) => {
	if (!showProperties) return
	if (RcGeoJsonData) {
		let selectedCostCenterProperties: Property[] = []
		for (let i = 0, len = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
			const cc = RcGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i]
			if (selectedCostCenterId == cc.id || selectedCostCenterId === undefined) {
				selectedCostCenterProperties = selectedCostCenterProperties.concat(cc.propertiesByCostCenterId?.nodes)
			} else {
				for (let i2 = 0, len = cc.propertiesByCostCenterId?.nodes.length; len > i2; i2++) {
					map.current.setFeatureState(
						{ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: cc.propertiesByCostCenterId?.nodes[i2].plotId },
						{ hover: false }
					)
				}
			}
		}

		for (let i2 = 0, len = selectedCostCenterProperties.length; len > i2; i2++) {
			map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: selectedCostCenterProperties[i2].plotId }, { hover: true })
		}
	}
}

type RoadUsageUnit = {
	id: number
	name: string
}

export type RoadUsageUnits = RoadUsageUnit[]

const PropertyDialog = () => {
	const { state, dispatch } = useMapState()

	return (
		<>
			{state.propertyDialogData == undefined ? null : (
				<Dialog
					open={state.propertyDialogData.open}
					onBackdropClick={() => dispatch({ type: "setPropertyDialogData", payload: { ...state.propertyDialogData, open: false } })}
				>
					<CustomDialogTitle>{state.propertyDialogData?.mmlPropertyId}</CustomDialogTitle>
					<DialogContent>
						<MapPropertyInfoPopup />
					</DialogContent>
					<CustomDialogActions>
						<IconButton
							sx={{ position: "absolute", right: 5, top: 5 }}
							onClick={() => dispatch({ type: "setPropertyDialogData", payload: { ...state.propertyDialogData, open: false } })}
						>
							<CloseIcon />
						</IconButton>
					</CustomDialogActions>
				</Dialog>
			)}
		</>
	)
}

export { MapComponent }
