import React, { useState } from "react"
import { toast } from "react-toastify"
import { Button, TextField } from "@mui/material"

/**
 * ChangePasswordFormProps:
 *  - changePassword: funktio, joka lähettää uuden salasanan backendille
 *  - changePasswordId: URL-parametreista luettava tunniste
 *  - onSuccess: callback, kun salasanan vaihto onnistui
 *  - onFail: callback, kun salasanan vaihto epäonnistui
 */
interface ChangePasswordFormProps {
	changePassword: (changePasswordId: string, newPassword: string) => Promise<any>
	changePasswordId: string
	onSuccess?: () => void
	onFail?: () => void
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ changePassword, changePasswordId, onSuccess, onFail }) => {
	const [newPassword, setNewPassword] = useState("")
	const [confirmNewPassword, setConfirmNewPassword] = useState("")

	/**
	 * Lähettää uuden salasanan, jos validointi on kunnossa.
	 */
	const handleChangePassword = async () => {
		if (!changePasswordId) {
			toast.error("Linkin tunnus puuttuu.")
			return
		}
		if (!newPassword || !confirmNewPassword) {
			toast.error("Salasana ei voi olla tyhjä.")
			return
		}
		if (newPassword !== confirmNewPassword) {
			toast.error("Salasanat eivät täsmää.")
			return
		}

		try {
			const res = await changePassword(changePasswordId, newPassword)
			if (res?.status < 300) {
				toast.success("Salasana vaihdettu onnistuneesti.")
				onSuccess && onSuccess()
			}
		} catch (error) {
			toast.error("Salasanan vaihtaminen epäonnistui.")
			onFail && onFail()
		}
	}

	return (
		<div className="login-page">
			<div className="change-password-container">
				<div style={{ maxWidth: 400, margin: "0 auto", padding: 20 }}>
					<h2 className="change-password-title">Vaihda salasana</h2>

					<TextField
						className="change-password-input"
						label="Uusi salasana"
						type="password"
						fullWidth
						margin="normal"
						value={newPassword}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
					/>
					<TextField
						label="Vahvista uusi salasana"
						type="password"
						fullWidth
						margin="normal"
						value={confirmNewPassword}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(e.target.value)}
					/>

					<Button className="change-password-button" variant="contained" color="primary" onClick={handleChangePassword} style={{ marginTop: 16 }}>
						Vaihda salasana
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ChangePasswordForm
